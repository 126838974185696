import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import {   useState } from "react";
import {   get_data_by_url } from "../../api/general";
import { useAuth, useNotification } from "../../hooks";
import { Col, Row } from "react-bootstrap";
import { InputField } from "../InputFields/InputFieldHelper";
import { GoogleMapLocation } from "../GoogleMapLocationPick/GoogleMapLocation";
import { styled, Tooltip, tooltipClasses, Zoom } from "@mui/material";
import { BsFillPencilFill } from 'react-icons/bs';

export default function ModarProfileAddress({ setReloadData, addressData }) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const [addressStates] = useState([]);
    const [addressCities] = useState([]);
    const [showLocationPicker, setShowLocationPicker] = useState(true);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [isMobileValid, setIsMobileValid] = useState(true); // To track mobile validity
    const validateMobile = (mobileNumber) => {
        const mobilePattern = /^[6-9][0-9]{9}$/; // Starts with 7, 8, or 9 followed by 9 digits
        return mobilePattern.test(mobileNumber);
    };
    const { showError, } = useNotification();
    const { authInfo } = useAuth();
    const [btnSubmit, setBtnSubmit] = useState('Update')
    const [messageError] = useState('')
    const [loading, setLoading] = useState(false)
    const stateAddress = {};
    const [address, setAddress] = useState({
        login_user_id: authInfo?.profile?.id || "",
        login_user_type: authInfo?.profile?.user_type || "",
        id: addressData?.id,
        name: addressData?.name || "",
        email: addressData?.email || "",
        mobile: addressData?.mobile || "",
        pincode: addressData?.pincode || "",
        name_state: addressData?.name_state || "",
        name_city: addressData?.name_city || "",
        address: addressData?.address || "",
        landmark: addressData?.landmark || "",
        device_latitude: addressData?.device_latitude || 0.00,
        device_longitude: addressData?.device_longitude || 0.00,
        remark: addressData?.remark || ""
    });

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission behavior

         // Check if mobile number is valid before submission
        if (!isMobileValid) {
            showError("Please enter a valid mobile number.");
            return; // Prevent form submission if mobile is invalid
        }
        const bodydata = {
            login_user_id: authInfo?.profile?.id || "",
            login_user_type: authInfo?.profile?.user_type || "",
            id: addressData?.id,
            values: {
                name: address?.name || "",
                email: address?.email || "",
                mobile: address?.mobile || "",
                pincode: address?.pincode,
                name_state: address?.name_state,
                name_city: address?.name_city,
                address: address?.address,
                landmark: address?.landmark,
                device_latitude: address?.device_latitude,
                device_longitude: address?.device_longitude,
                remark: address?.remark,
            }
        }
        setBtnSubmit("loading...");
        setLoading(true);
        setReloadData(true);

        const response = await get_data_by_url("dealer/address-update", bodydata);
     //   console.log("response----", response)
        setBtnSubmit("Update");
        setLoading(false);

        if (response?.error === false) {
            // showSuccess(response?.message || "Address added successfully");
            handleClose();
            setReloadData(false);

        } else {
            showError(response?.message || "Error encounter");
        }
        setReloadData(false);


    };
    


    const handleChange = (e) => {
        const { name, value } = e.target;
        // if (name === 'name_state') {
        //     getCityName(value).then(r => r);
        //     setAddress({...address, "name_city": ""});
        // }
        // console.log(e.target);

        // Validate mobile number when the 'mobile' field is changed
        if (name === 'mobile') {
            const isValid = validateMobile(value);
            setIsMobileValid(isValid); // Track validity of mobile number

            // if (!isValid) {
            //     showError("Please enter a valid Indian mobile number.");
            //     return; // Do not update state if validation fails
            // }
        }

        setAddress({ ...address, [name]: value });
    };


    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} arrow TransitionComponent={Zoom} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    console.log("address" ,address);

    return (
        <div>
            <LightTooltip title={"Update"} placement="top">
                <Avatar sx={{ width: 24, height: 24 }} onClick={handleClickOpen} className="address-update mx-1" >
                    <BsFillPencilFill size={14} />
                </Avatar>
            </LightTooltip>

            {/* <Fab variant="extended" size="small" color="primary" onClick={handleClickOpen}>
                <FaTimes sx={{ mr: 3 }} />
                Cancel Order
            </Fab>*/}
            <Dialog open={open} onClose={handleClose}
                fullScreen={fullScreen}
                fullWidth
                maxWidth="lg"
                // scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description">
                <DialogTitle>Add New Address
                    {!showLocationPicker && <Button variant={'contained'} color={'warning'}
                        className={'sign-up-button m-1 float-end'}
                        onClick={() => setShowLocationPicker(true)}>Pick Location</Button>}
                </DialogTitle>
                <DialogContent>
                    {showLocationPicker ?
                        // <AddressPickLocation stateAddress={stateAddress} address={address} setAddress={setAddress}/>
                        <GoogleMapLocation addressMain={address} setAddressMain={setAddress} isUpdate={true} />
                        // <ReactGoogleMapPicker addressMain={address} setAddressMain={setAddress}/>

                        : (<Row className={'gx-3 gy-0'}>
                            <Col md={12}>
                                <InputField type={'text'} minLength={3} name={'name'}
                                    label={'Name'} value={address?.name}
                                    placeholder={'Enter your name'}
                                    onChange={handleChange} required={true}
                                />
                                {/*<TextField variant="outlined" id="address-name" label="Name"  />*/}
                            </Col>
                            <Col md={6}>
                                <InputField appendType={'start'} appendIcon={'+91'} type={'text'}
                                    min={1000000000} max={9999999999} name={'mobile'}
                                    label={'Mobile Number'}
                                    placeholder={'Enter your mobile number'}
                                    onChange={handleChange}
                                    value={address?.mobile} required={true}
                                />
                            </Col>
                            <Col md={6}>
                                <InputField type={'text'} name={'email'}
                                    label={'Email'} value={address?.email}
                                    placeholder={'Your email address'}
                                    onChange={handleChange} required={false} />
                            </Col>

                            <Col md={12}>
                                {/*<TextField className={'form-control w-100'} name={'address'} label={'Address'}
                                                           value={address?.address} variant="standard"
                                                           onChange={handleChange} required={true}/>*/}
                                <InputField type={'textarea'} name={'address'} label={'Address'}
                                    value={address?.address}
                                    placeholder={'Your address'}
                                    onChange={handleChange} required={true} />
                            </Col>
                            <Col md={4}>
                                <InputField type={'text'} minLength={3} name={'name_city'}
                                    label={'City'} value={address?.name_city}
                                    placeholder={'Your city name'}
                                    onChange={handleChange} required={true}
                                    options={addressCities} />
                            </Col>
                            <Col md={4}>
                                <InputField type={'text'} minLength={3} name={'name_state'}
                                    label={'State'} value={address?.name_state}
                                    placeholder={'Your state name'}
                                    onChange={handleChange}
                                    required={true}
                                    options={addressStates} />
                            </Col>
                            <Col md={4}>
                                <InputField type={'number'} min={100000} max={999999} name={'pincode'}
                                    label={'Pincode'} value={address?.pincode}
                                    placeholder={'Your Area pincode'}
                                    onChange={handleChange} required={true}
                                />
                            </Col>
                            <Col md={12}>
                                                                    <InputField type={'text'} name={'landmark'} label={'Landmark'}
                                                                                value={address?.landmark}
                                                                                placeholder={'Please enter landmark'}
                                                                                onChange={handleChange} required={true}/>
                                                                </Col>
                            <Col md={12}>
                                <InputField type={'text'} name={'remark'}
                                    label={'Remark (Any delivery instructions)'}
                                    value={address?.remark}
                                    placeholder={'Enter any remark?'}
                                    onChange={handleChange} required={false} />
                            </Col>
                            {/*<Col md={6}>
                                        <InputField type={'number'} name={'device_latitude'}
                                                    label={'device_latitude'} value={address?.device_latitude}
                                                    placeholder={'Your Area device_latitude'}
                                                    onChange={handleChange}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <InputField type={'number'} name={'device_longitude'}
                                                    label={'device_longitude'} value={address?.device_longitude}
                                                    placeholder={'Your Area device_longitude'}
                                                    onChange={handleChange}
                                        />
                                    </Col>*/}
                        </Row>)}
                    <p>
                        <small className={'text-danger capitalize'}>{messageError}</small>
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={'outlined'} color={'inherit'}>Cancel</Button>
                    {showLocationPicker ? <Button variant={'outlined'} color={'warning'} className={'sign-up-button'}
                        onClick={() => {
                            setShowLocationPicker(false);
                            // console.log(stateAddress);
                            setAddress({ ...address, ...stateAddress })
                        }}>Confirm Location Or Next</Button> :
                        <Button disabled={loading} variant={'contained'} color={'warning'} className={'sign-up-button'}
                            onClick={handleSubmit}> {loading &&
                                <CircularProgress size={16} color={"inherit"} />}&nbsp; {btnSubmit}</Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
}