import client from "./client";

const authToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYjQxYjcyN2VkOTY5NGMzODQ1ZjM3ZDFmMTkwMjAyYzMxODY4YmU2MTc3OGI2ZjJlOGMwNjY2MzZkZjhhMGE5NzE0ZWE4ZGE2MTBlMjkxYWMiLCJpYXQiOjE2Nzc4MjI2MTEuMTk0MDYxLCJuYmYiOjE2Nzc4MjI2MTEuMTk0MDcsImV4cCI6MzMyMzQ3MzE0MTEuMTYwNjYsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.zTZvF547MhKLOm9sAfqs1Y5hRTpSziPc8TEAV_N_XnukXUoi3KxBHLl6J2avQm2vw_IvIw9Wmvjun8jZjF2xxRICx157cL12lw7TMruNqkynWhZ5k0V_FnP6KrcUO9mTOMR9gNj_mASpHDeMqFeFIJChPWxvd_1lslPaJ91om5FHFnhm8OzzRZXQweSFM9J0UO5t4UfixkWoG0xatubyeFkVp4GsYusoG4UGYFSGCj5aM2KqPmg6LvMIh6skJraV9ufSdj7mWMXCLoA8feSl40bvpHttfdaI709gb3ajyXMtksKt6fGILD8_kESxvP4N1HYdMGO49M2y8ASOHBV5d7OPzYl0PoiTtWSTnmhiJbflFsbczI8OGfYG0cubb8nSQDxRJQ51QReIwO7UCuxnEnDG8Gz7IKOg8ahnarcmBrW2zxr0hM1UmOeAsry9gB-Q8RABcB7fUPBMZN45r6oJqF5inr6qYKouS8_sd78ebUY-jAyYvSFyCL_uZ3lTDic4qXTtICf981GwSa6uGKcdR8UYrq0xbiU0N7RAAua2TF0aVfA6waMT8aCfjuvQA-ypk64YyydkfPr8QOUrqByuNA6OWgkkcdsKSUrD8V6dJvg3ZLEukS_lOTi5SZcvIpjaIQnVKpCgNcLVyqEz-e7ENEr81F0zepDfROlxCeehCpg"
const fetchHeader = {
    Authorization: "Bearer " + authToken,
    accept: "application/json",
};
const requiredInput = {
    device_type: "web",
    istoken: "ISABCDEFGH",
    device_token: localStorage.getItem('device_token') || "ABCDEFGH",
    // login_user_id: localStorage.getItem('id') || 0,
    // login_user_type: localStorage.getItem('user_type') || "client",
};

export const model = async ({brand_slug, category_id}) => {
    const details = {
        offset: 0,
        limit: 0,
    }
    if (brand_slug) {
        details.slug_brand = brand_slug;
    }
    if (category_id) {
        details.category_id = category_id;
    }

    try {
        return await client.post("/product/brand", {...details, ...requiredInput}, {
            headers: {...fetchHeader},
        });
    } catch (error) {
        const {response} = error;

        if (response?.data) return {error: response.data.message};


        return {error: error.message || error};
    }
};


export const product_brand = async ({brand_slug, category_id, category_slug, limit}) => {
    const details = {
        // device_type: "web",
        // istoken: "ISABCDEFGH",
        offset: 0,
        limit: 0,
    }
    if (limit) {
        details.limit = limit;
    }
    if (category_slug) {
        details.slug_category = category_slug;
    }

    if (brand_slug) {
        details.slug_brand = brand_slug;
    }

    if (category_id) {
        details.category_id = category_id;
    }
    try {
        return await client.post("/product/brand", {...details, ...requiredInput}, {
            headers: {...fetchHeader},
        });
    } catch (error) {
        const {response} = error;

        if (response?.data) return {error: response.data.message};


        return {error: error.message || error};
    }
};

// console.log("product_brand",product_brand);

export const category_list = async (inputData) => {
    // console.log(inputData);
    const details = {
        offset: 0,
        limit: 0,
    }
    // if (category_id) {
    //     details.category_id = category_id;
    // }
    if (inputData?.slug_category) {
        details.slug_category = inputData?.slug_category;
    }
    if (inputData?.is_service) {
        details.is_service = inputData?.is_service;
    }
    if (inputData?.is_part) {
        details.is_part = inputData?.is_part;
    }
    // console.log("details",details);
    // console.log(inputData?.is_service)
    try {
        const data = await client.post("/product/category", {...details, ...requiredInput}, {
            headers: {...fetchHeader},
        });

        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};


        return {error: error.message || error};
    }

};


export const category_slug = async ({category_slug}) => {
    const details = {
        offset: 0,
        limit: 0,
    }
    if (category_slug) {
        details.slug_category = category_slug
    }
    try {
        const data = await client.post("/product/category", {...details, ...requiredInput}, {
            headers: {...fetchHeader},
        });

        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};


        return {error: error.message || error};
    }
};
let ajaxRequest = null;
export const product_model = async ({
                                        part_type,
                                        category_id,
                                        slug_category,
                                        brand_id,
                                        slug_value,
                                        slug_brand,
                                        slug_model,
                                        checkData
                                    }) => {
    const details = {
        offset: 0,
        limit: 0,
    }

    if (category_id) {
        details.category_id = category_id;
    }
    if (slug_category) {
        details.slug_category = slug_category;
    }
    if (brand_id) {
        details.brand_id = brand_id;
    }
    if (slug_value) {
        details.slug_brand = slug_value;
    }
    if (slug_brand) {
        details.slug_brand = slug_brand;
    }
    if (slug_model) {
        details.slug_model = slug_model;
    }
    if (part_type) {
        details.part_type = part_type;
    }

    try {

        // if (ajaxRequest) {
        //     ajaxRequest.cancel();
        // }
        // ajaxRequest = axios.CancelToken.source();


        return await client.post("/product/model", {...details, ...requiredInput}, {
            headers: {...fetchHeader},
            // cancelToken: ajaxRequest.token
        });
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        return {error: error.message || error};
    }
};

export const part_list = async ({
                                    login_user_id,
                                    login_user_type,
                                    brand_id,
                                    model_id,
                                    brand_true,
                                    model_true,
                                    part_id,
                                    part_true,
                                    slug_part,
                                    offset,
                                    user_id,
                                    user_type,
                                    search,
                                    slug_brand,
                                    part_type,
                                    category_id
                                }) => {
    const details = {
        offset: offset,
        limit: 12,
    }
    if (user_id) {
        details.login_user_id = user_id
        details.login_user_type = user_type
    }
    if (login_user_id) {
        details.login_user_id = login_user_id
        details.login_user_type = login_user_type
    }
    if (search) {
        details.search_text = search;
    }
    if (brand_true) {
        details.brand_id = brand_id;
    }
    if (model_id) {
        details.model_id = model_id;
    }
    if (part_true) {
        details.part_id = part_id;
    }
    if (slug_part) {
        details.slug_part = slug_part;
    }
    if (slug_brand) {
        details.slug_brand = slug_brand;
    }
    if (part_type) {
        details.part_type = part_type;
    }
    if (category_id) {
        details.category_id = category_id;
    }

    // console.log(details)

    try {
        const data = await client.post("/product/part", {...details, ...requiredInput}, {
            headers: {...fetchHeader},
        });

        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        return {error: error.message || error};
    }
};


export const home = async () => {

    try {
        // console.log(requiredInput);
        const data = await client.post("/dealer/front-home", {...requiredInput, 'offset': 0, 'limit': 10}, {
            headers: {...fetchHeader},
        });
// console.log(data);
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        return {error: error.message || error};
    }
};

export const fetchReviews = async ({id, user_type}) => {
    const details = {
        login_user_type: user_type || "client",
        login_user_id: id,
        offset: 0,
        limit: 10,
        from_page: "web"
    }
    try {
        // console.log("input:", requiredInput);
        // console.log("details:", details);
        

        const data = await client.post("/dealer/user_review", 
            { ...details, ...requiredInput }, 
            { headers: { ...fetchHeader } }
          );          
        console.log("response" , data.data);
        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        return {error: error.message || error};
    }
  };


export const model_problem = async ({id, user_type}) => {
    const details = {
        login_user_type: user_type || "client",
        login_user_id: id,
        offset: 0,
        limit: 10
    }

    try {
        const data = await client.post("/general/model-problem", {...details, ...requiredInput}, {
            headers: {...fetchHeader},
        });

        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        return {error: error.message || error};
    }
};

export const offer_coupon = async ({id, user_type, code}) => {
    const details = {
        login_user_type: user_type || "client",
        login_user_id: id,
        offset: 0,
        limit: 10
    }
    if (code) {
        details.code = code;
    }

    try {
        const {data} = await client.post("/general/coupon-code", {...details, ...requiredInput}, {
            headers: {...fetchHeader},
        });

        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        return {error: error.message || error};
    }
};

export const about = async (slug) => {

    const details = {
        page_id: 1,
    }

    if (slug) {
        details.slug_page = slug;
    }

    try {
        const data = await client.post("/general/page", {...details, ...requiredInput}, {
            headers: {...fetchHeader},
        });

        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        return {error: error.message || error};
    }
};
export const get_general_page_content = async (inputData) => {

    // console.log(inputData);
    try {
        const {data} = await client.post("/general/page", {...inputData, ...requiredInput}, {
            headers: {...fetchHeader},
        });

        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        return {error: error.message || error};
    }
};

export const address_state = async (details) => {
    try {
        const req = {...requiredInput, ...details};
        // console.log("skfja;");
        // console.log(req);
        const {data, message, error} = await client.post("/general/city", req, {
            headers: {...fetchHeader},
        });
        // console.log(message);
        // console.log(error);
        // console.log(data);
        return {data, message, error};
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        return {error: error.message || error};
    }
};
export const get_coupon_codes = async (inputData) => {

    // console.log(inputData);
    try {
        const {data} = await client.post("/general/coupon-code", {...inputData, ...requiredInput}, {
            headers: {...fetchHeader},
        });

        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        return {error: error.message || error};
    }
};

export const get_data_by_url = async (url, inputData) => {

    // console.log(inputData);
    try {
        return await client.post(url, {...inputData, ...requiredInput}, {
            headers: {...fetchHeader},
        }).then(r => r.data);
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        return {error: error.message || error};
    }
};

export const contact_lead = async (contactInfo) => {
    try {
        const res = await client.post("/general/contact-lead-update", {...contactInfo, ...requiredInput}, {
            headers: {...fetchHeader},
        });

        return {message: res.data.message};
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        return {error: error.message || error};
    }
};

// mayu changes - Added product_search function
export const product_search = async ({
                                    login_user_id,
                                    login_user_type,
                                    brand_id,
                                    model_id,
                                    brand_true,
                                    model_true,
                                    part_id,
                                    part_true,
                                    slug_part,
                                    offset,
                                    user_id,
                                    user_type,
                                    search,
                                    slug_brand,
                                    part_type,
                                    category_id,
                                    category_slug
                                }) => {
    const details = {
        offset: offset,
        limit: 12,
    }
    if (user_id) {
        details.login_user_id = user_id
        details.login_user_type = user_type
    }
    if (login_user_id) {
        details.login_user_id = login_user_id
        details.login_user_type = login_user_type
    }
    if (search) {
        details.search_text = search;
    }
    if (brand_true) {
        details.brand_id = brand_id;
    }
    if (model_id) {
        details.model_id = model_id;
    }
    if (part_true) {
        details.part_id = part_id;
    }
    if (slug_part) {
        details.slug_part = slug_part;
    }
    if (slug_brand) {
        details.slug_brand = slug_brand;
    }
    if (part_type) {
        details.part_type = part_type;
    }
    if (category_id) {
        details.category_id = category_id;
    }
    if (category_slug) {
        details.category_slug = category_slug;
    }

    // console.log(details)

    try {
        const data = await client.post("/product/search", {...details, ...requiredInput}, {
            headers: {...fetchHeader},
        });

        return data;
    } catch (error) {
        const {response} = error;
        if (response?.data) return {error: response.data.message};
        return {error: error.message || error};
    }
};
// eOf mayu changes - Added product_search function