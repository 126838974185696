import React, { useEffect, useState } from "react";
import {  book_service_step } from "../../api/booking";
import { useAuth, useNotification } from "../../hooks";
// import { Col, Row } from "react-bootstrap";
import {  CForm, CFormInput } from "@coreui/react"; 
import { useNavigate,useSearchParams } from "react-router-dom";

import {cart} from "../../api/cart";
import {ApiCall} from "../../api/HelpingMethod";
import ModalAddressAdd from "../Cart/ModalAddressAdd";
import Radio from '@mui/material/Radio';
import {CircularProgress, FormControl, FormControlLabel, RadioGroup,} from "@mui/material";
import {Card, Col, Row} from "react-bootstrap";
// import "./Need.css";
export function NeedBody({ data, setData, token, handleBack }) {
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [list, setList] = useState([
        "09AM-11AM",
        "11AM-01PM",
        "01PM-03PM",
        "03PM-05PM",
        "05PM-07PM",
        "07PM-09PM",
    ]);


    const [dateMin, setDateMin] = useState(new Date().toISOString().split("T")[0]);
    const [dateMax, setDateMax] = useState(new Date());
    const addDays = () => {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() + 7);
        setDateMax(newDate.toISOString().split("T")[0]);
    };

    useEffect(() => {
        addDays();
    }, []);
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false)
    const [btnName, setButtonName] = useState('Submit')
    const { showError, showSuccess } = useNotification()
    const { authInfo } = useAuth()
    const [loading, setLoading] = useState(false)

    const [isAddressSelect, setIsAddressSelect] = useState(false)
    const [cartAddress, setCartAddress] = useState([])
    const [updated, setUpdated] = useState(false);
    const [loadingBtn, setButtonLoading] = useState(false)
    const { setOpenBackDrop } = useNotification();

    const dateChange = (e) => setDate(e.target.value);

    const handleSelect = (element) => setTime(element);


    useEffect(() => {
        if (data) {
            if (data?.expected_visit_date) {
                setDate(data?.expected_visit_date)
            }
            if (data?.expected_visit_time) {
                setDate(data?.expected_visit_time)
            }
            setCurrentTime(data?.expected_visit_date || "", data?.expected_visit_time || "");
        }
    }, [data])


    const setCurrentTime = (visitDate, visitTime) => {
        const dateTemp = new Date();
        const currTimeHour = dateTemp.getHours();
        const currTimeMinute = dateTemp.getMinutes();
        if (visitDate) {
            setDate(visitDate);
        } else {
            setDate(dateMin);
        }
        if (visitTime) {
            setTime(visitTime)
        } else {
    
            if (currTimeHour < 9) {
                setTime("09AM-11AM")
            } else if (currTimeHour < 11) {
                setTime("11AM-01PM")
            } else if (currTimeHour < 13) {
                setTime("01PM-03PM")
            } else if (currTimeHour < 15) {
                setTime("03PM-05PM")
            } else if (currTimeHour < 17) {
                setTime("05PM-07PM")
            } else if (currTimeHour < 19) {
                setTime("07PM-09PM")
            } else {
                if (visitDate) {
                    setDate(visitDate);
                } else {
                    dateTemp.setDate(dateTemp.getDate() + 1);
                    setDate(dateTemp.toISOString().split("T")[0]);
                }
                setTime("09AM-11AM")
            }
        }


    }

 
    
        const [addressBody] = useState({
            "login_user_id": authInfo?.profile?.id,
            "login_user_type": authInfo?.profile?.user_type,
            "limit": 3,
            "offset": 0
        })


        
            const [searchParams, setSearchParams] = useSearchParams();
            const [tokenPart, setTokenPart] = useState(searchParams.get("token_part") || null);
            const [locationInfo, setLocationInfo] = useState({
                login_user_id: authInfo.profile.id,
                login_user_type: authInfo.profile.user_type,
                step: "location-info",
                token_order: token,
                name: authInfo.profile?.name,
                mobile: authInfo.profile?.mobile,
                email: authInfo.profile?.email,
                name_state: "",
                name_city: "",
                address: "",
                pincode: "",
                device_latitude: "0.00",
                device_longitude: "0.00",
                from_page:"combine",

            });

               useEffect(() => {
                // console.log(JSON.stringify(data))
                    if (data?.order_address?.address_id) {
                        
                        setIsAddressSelect(data?.order_address?.address_id || "")
                    }
                }, [data])
            
                useEffect(() => {
                    document.title = 'Confirm Location | iService - Innovation Service';
                }, [])
            
                const getData = async ({ token_part, coupon, couponTrue }) => {
                    let inpDt = {
                        id: authInfo?.profile.id,
                        user_type: authInfo?.profile.user_type,
                        coupon: coupon,
                        couponTrue: couponTrue
                    }
                    if (token_part) {
                        inpDt.token_part = token_part;
                    }
                    const { data } = await cart(inpDt);
                    // console.log("datadata--", data);
                    if (data.error) {
                        showError(data.message);
                    } else {
                        // if (data?.data?.parts) await setCart_details(data?.data?.parts);
                        // if (data?.data) await setWhole_details(data?.data);
                    }
                    return data;
                    // console.log(whole_details);
                };
                const getCartAddress = async (e) => {
                    setLoading(true);
                    setOpenBackDrop(true);
                    const result = await ApiCall("/dealer/address", addressBody)
                   // console.log("result---", result)
                    if (result?.data) {
                        if (authInfo?.profile.user_type === 'dealer' && result?.data.length > 1) {
                            setIsAddressSelect(result?.data[1]?.id || 0);
                        } else {
                            setIsAddressSelect(result?.data[0]?.id || 0);
                        }
                    }
                    setCartAddress(result?.data);
                    setLoading(false);
                    setOpenBackDrop(false);
                    setUpdated(false);
                }
                useEffect(() => {
                    getData({ token_part: tokenPart, coupon: "", couponTrue: false });
                    getCartAddress();
                }, [updated, tokenPart]);
                const onSelectAddress = (id) => {
                    // console.log(id);
                    setIsAddressSelect(id);
                }

                const handleSubmit = async (event) => {
                    event.preventDefault(); // Prevent form submission reloading
                
                    setButtonName("loading...");
                    setLoading(true);
                
                    const form = event.currentTarget;
                    // if (form.checkValidity() === false) {
                    //     setButtonName("Next");
                    //     setLoading(false);
                    //     event.stopPropagation();
                    //     return;
                    // }
                
                    const sendData = {
                        step: "visit-schedule",
                        token_order: token,
                        login_user_id: authInfo.profile.id,
                        login_user_type: authInfo.profile.user_type,
                        expected_visit_date: date,
                        expected_visit_time: time,
                        name: authInfo.profile?.name,
                        mobile: authInfo.profile?.mobile,
                        email: authInfo.profile?.email,
                        name_state: "",
                        name_city: "",
                        address: "",
                        pincode: "",
                        device_latitude: "0.00",
                        device_longitude: "0.00",
                        from_page:"combine",

                    };
                
                    try {
                        const { data, error } = await book_service_step({ details: sendData, isImage: false });
                
                        if (error || data?.error) {
                            showError(data?.message || "Something went wrong");
                        } else {
                            console.log("DataSending: " + JSON.stringify(data));
                            setData(data?.data);
                            navigate(`/booking-details/${token}?order=${token}`, {
                                state: { token: token },
                                replace: true,
                            });
                        }
                    } catch (err) {
                        console.error("Submission Error:", err);
                        showError("An unexpected error occurred.");
                    } finally {
                        setButtonName("Next");
                        setLoading(false);
                    }
                };
                // console.log("Sending Data: ", sendData);


    return (
        <>
            <div className="dateContainer">
                <CForm className="needs-validation"
                    noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className={"g-4 "}>
                        <Col className={'mx-auto '} md={6}>
                            <CFormInput type="date" onChange={dateChange}
                            className="book-date"
                                min={dateMin} max={dateMax}
                                value={date}
                                placeholder="Select date which engineer visit" />
                        </Col>
                        <Col md={12}>
                            <div className="selectionDate">
                                {list.map((element, idx) => {
                                    // console.log(element);
                                    return (
                                        <button key={idx} onClick={() => handleSelect(element)} value={element}
                                            type={'button'}
                                            className={`Selectiondatebtn ${time === element ? "Selectiondatebtn1" : ""}`}>
                                            {element}
                                        </button>
                                    );
                                })
                                }
                            </div>

                        </Col>
                    </Row>

                    {!loading && (
                        <div className="row d-flex justify-content-center mt-5 mb-4">
                            {cartAddress && Array.isArray(cartAddress) && cartAddress.length > 0 ?
                                <>
                                    <h5 className="mb-0 mx-3 product-info-heading col-lg-12">Select Location</h5>
                                    <ModalAddressAdd reloadData={updated} setReloadData={setUpdated}/>
                                    <div className=" py-2" style={{maxHeight: "500px", overflow: "auto"}}>

                                        <FormControl sx={{m: 1}} error={false} variant="standard">
                                            <RadioGroup aria-labelledby="demo-error-radios"
                                                        name="address_id"
                                                        value={isAddressSelect || 0}
                                                // onChange={handlePaymentModeChange}
                                            >
                                                {cartAddress && Array.isArray(cartAddress) && cartAddress.map((address, index) => {
                                                    return (
                                                        <Row className={'mb-2'}
                                                             key={address?.token || address?.id || index}>
                                                            <Col>
                                                                <Card style={{cursor: "pointer"}}
                                                                      onClick={() => onSelectAddress(address.id)}
                                                                      className={isAddressSelect === address?.id ? "select-address" : ""}>
                                                                    <Card.Body>
                                                                        <FormControlLabel value={address?.id}
                                                                                          control={<Radio
                                                                                              color={"warning"}/>}
                                                                                          label={address?.full_address || "No Address"}/>
                                                                    </Card.Body>
                                                                </Card>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })}

                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </> :
                                <>
                                    <p className={"my-5 ms-5"}>
                                        <ModalAddressAdd reloadData={updated} setReloadData={setUpdated}
                                                         place={'center'} defaultOpen={true}/>
                                    </p>
                                </>
                            }
                        </div>
                    )}
                    <Row className={"my-4"}>
                        <Col>
                            <button className="button-2 float-left"
                                onClick={handleBack}>{"Previous"}</button>
                        </Col>
                        <Col>
                            <button disabled={loading}
                                className={"sign-up-button float-end " + (loading && 'disabled-button')}
                                type={'submit'}>{loading &&
                                    <CircularProgress size={16} color={"inherit"} />}&nbsp; {btnName}</button>
                        </Col>
                    </Row>

                    {/*<button className="sign-up-button mx-auto mt-5" type={"submit"}>{btnName}</button>*/}
                </CForm>
            </div>
        </>
    );
}
