import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { FaAngleDown } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";

export function ProblemPageBody({ data }) {
    const removeExtraSpace = (s) => s.trim().split(/ ,+/).join(', ');
    const model = data?.device_info?.title_model || "";
    const brand = data?.device_info?.title_brand || "";
    const iemi = data?.device_info?.device_serial_imei || ""; 
    const problem = data?.device_info?.device_problem || "";
    const otherProblem = data?.device_info?.device_problem_other || "";
    const visitDate = data?.expected_visit_date || "";
    const visitTime = data?.expected_visit_time || "";

    // console.log(JSON.stringify(data))
  const fontStyle = { fontSize: ".8rem" };

    return (
        <Accordion style={{ minWidth: "100%" }} className={'text-start mb-sm-5 mb-2'}>
            <AccordionSummary expandIcon={<FaAngleDown />} aria-controls="panel1a-content" id="panel1a-header">
                <b>Details:</b>
            </AccordionSummary>
            <AccordionDetails>
                <Row xs={{ cols: 1 }} className={'g-2 text-start'}>
                    {(brand) && (
                        <Col>Brand: <b style={fontStyle}>{brand}</b></Col>
                    )}
                    {(model) && (
                        <Col>Model: <b style={fontStyle}>{model}</b></Col>
                    )}
                    {iemi && (
                        <Col>IMEI Number: <b style={fontStyle}>{iemi}</b></Col>
                    )}
                    {(problem || otherProblem) && (
                        <Col>Problems: <b style={fontStyle}>{problem ? removeExtraSpace(problem) : "other"} {otherProblem ? " / " + otherProblem : ""}</b></Col>
                    )}
                    {visitDate && (
                        <Col>Visit Date: <b style={fontStyle}>{visitDate || ""}</b></Col>
                    )}
                    {visitTime && (
                        <Col>Visit Time: <b style={fontStyle}>{visitTime || ""}</b></Col>
                    )}
                </Row>
            </AccordionDetails>
        </Accordion>
    )
        ;
}
