import {React, useEffect, useState} from "react";
// import Crouser from "./Crouser";
import {BeatLoader} from "react-spinners";
import {Row} from "react-bootstrap";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import Crouser2 from "./Crouser2";
import Crouser from "./Crouser";


export default function HomeBrandListSlider({brands}) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        setLoading(true);
        setData(brands);
        setLoading(false);
    }, [brands]);

    return (
        <>
            {data && Array.isArray(data) && data.length > 0 && (
                <div className=" my-5 px-5">
                    {loading && <div className="container">
                        <h1 className={`buy-parts-div text-dark my-5 mx-5`}>
                            <SkeletonFileGeneral height={15} total={1} col={12} mx={'auto'}/>
                        </h1>
                        <Row>
                            <SkeletonFileGeneral height={140} total={5} col={2} mx={'auto'}/>
                        </Row>
                    </div>}
                    <div className="container">
                        <div className="text-center">
                            {loading && <BeatLoader color="#ee610f" size={25}/>}
                        </div>
                    </div>
                    <div>
                        <h3 className={`text-dark`}> Top brands </h3>
                        {/*<Crouser brands={data} basePath={'/shop?category-slug=' + '&brand-slug='}/>*/}
                        <Crouser2 brands={data} basePath={'/product?category-slug=' + '&brand-slug='}/>
                    </div>
                </div>
            )}
        </>
    );
}
