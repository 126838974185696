import {React, useEffect, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import "../styles/PartPurchase.css";

import Tabskbar from "./Taskbar";
import {category_list, category_slug, home, model} from "../../api/general";
import {BsSearch} from "react-icons/bs";
import {useAuth, useNotification} from "../../hooks";
import {ApiCall} from "../../api/HelpingMethod";
import noitem from "../asset/Web Ad4.webp"
import {Button, Col, Row} from "react-bootstrap";




export default function PartPurchase() {
    const [searchParams] = useSearchParams();
    const [category_details, setCategory_details] = useState([]);
    const [brand_details, setBrand_details] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const [brand_id, setBrand_id] = useState();
    const [selected_category, setSelected_category] = useState(null);
    const [selected_categoryId, setSelected_categoryId] = useState(false);
    const [selected_title, setSelected_Title] = useState(false);
    const [selected_brandId, setSelected_brandId] = useState(false);
    const [search, setSearch] = useState(searchParams.get("search_text") || "");
    const [brandSlug, setBrandSlug] = useState(searchParams.get("brand-slug") || "");
    const [modelSlug, setModelId] = useState(searchParams.get("model-slug") || "");
    // const [slugCategory, setSlugCategory] = useState(searchParams.get("category-slug") || "");
    const {showError, setOpenBackDrop, setShowLoginModel} = useNotification();
    const {authInfo} = useAuth();
    const [partTypeList, setPartTypeList] = useState([])
    const [part_type, setPart_type] = useState(0)
    const [categoryId, setCategoryId] = useState(0)

    const navigate = useNavigate();


    const location = useLocation();
    const { homePart } = location.state || {};
 
    // console.log("home data"+homePart.title);



    const [bodyData, setBodyData] = useState({
        "login_user_id": authInfo.profile.id,
        "login_user_type": authInfo.profile.user_type,
        "limit": 0,
        "offset": 0
    })
    const brand = async () => {
        const data = await model({brand_slug: searchParams.get("brand-slug")});
        
        setBrand_id(data.data.data.id);
        setSelected_brandId(data.data.data.id);
        setSelected_Title(data.data.data.title);
    };
    const categorySlug = async () => {
        const {data} = await category_slug({
            category_slug: searchParams.get("category-slug"),
        });
        setSelected_category(data?.data?.title || "");
        setSelected_categoryId(data?.data?.id);
        onDefault(data?.data?.id);
    };
    const getData = async () => {
        setOpenBackDrop(true);
        const {data} = await category_list({is_part: "yes"});
        setCategory_details(data.data);
        setOpenBackDrop(false);
    };
    useEffect(() => {
        if (searchParams.get("category-slug")) {
            categorySlug();
        }
        if (searchParams.get("brand-slug")) {
            brand();
        }
        window.scrollTo(0, 0);
        getData();
    }, []);

    const onChangeCategory = async (event) => {
        setSelected_Title(false);
        setBrand_id("");
        setSelected_brandId("");
        const value = event.target.value;
        setSelected_categoryId(value);
        setOpenBackDrop(true);
        setCategoryId(value)
        const {data} = await model({category_id: value});
        setBrand_details(data.data);
        setOpenBackDrop(false);
    };
    const onDefault = async (id) => {
        setOpenBackDrop(true);
        setSelected_categoryId(id)
        setCategoryId(id)
        const {data} = await model({category_id: id});
        setBrand_details(data.data);
        setOpenBackDrop(false);
    };


    const getPartType = async () => {
        setOpenBackDrop(true);
        const {data} = await ApiCall('dealer/part_type', bodyData)
        // console.log("Getting data: "+JSON.stringify(data))
        setPartTypeList([...[{'id': 0, 'title': "All"}], ...data])
        setOpenBackDrop(false);
    }

    useEffect(() => {
        getPartType()
    }, [])

    const onChangeBrand = async (event) => {
        const value = event.target.value;
        setBrand_id(value);
        setSelected_brandId(value);
    };

    const handlePartTypeChange = async (e) => {
    
        setOpenBackDrop(true);
        const value = e.target.value;
        // console.log(value)
        setPart_type(value)
        setOpenBackDrop(false);
    }
    useEffect(()=>{
        if (homePart) {
                    setPart_type(homePart.title);
                }
    })
    // const handlePartTypeChange = async (e) => {
    //     setOpenBackDrop(true); // Show loading backdrop
    
    //     const value = e.target.value;
        
    //     // Check if homePart data is available
    //     if (homePart) {
    //         // Use homePart.partType if available
    //         setPart_type(homePart);
    //     } else {
    //         // Otherwise, use the selected value from the event
    //         setPart_type(value);
    //     }
    
    //     setOpenBackDrop(false); // Hide loading backdrop
    // };


    const handleChangeSearch = async (event) => {
        setSearch(event.target.value);
        setTabIndex(0);
    };

    useEffect(() => {
        setSearch(searchParams.get("search_text"));
        setTabIndex(0);
    }, [searchParams.get("search_text") || ""])


    return (
        <div>
            <div className=" mx-5">
                <div className="container my-sm-5 my-3">
                    <div className="row mb-2">
                        <div className="col-12 text-end">
                            {authInfo.profile.user_type === "dealer" ?
                                <Button onClick={() => navigate('/quick-entry')} variant=""
                                        className="btn btn-primary1 my-1 me-2">Quick order</Button> : <></>}

                            <Button
                                onClick={() => authInfo.profile.id ? navigate('/part-request') : setShowLoginModel(true)}
                                variant="" className="btn btn-primary1 my-1 ps-3 pe-3">Custom Part Request</Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-1 my-2 fw-bold fs-5">Filter:</div>
                        <div className="col-md-11">
                            <div className="row g-3">
                                <div className="col-12 col-md-3">
                                    <div className="main-form">
                                        <div className="has-search">
                                            <span className="fa fa-search form-control-feedback"> <BsSearch/></span>
                                            <input type="text" className="form-control" placeholder="Search"
                                                   autoFocus={true}
                                                   value={search || ""}
                                                   onChange={handleChangeSearch}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12 col-md-3">
                                    <select onChange={onChangeCategory} className="form-select"
                                            aria-label="Default select example" value={selected_categoryId || ""}>
                                        <option value="" disabled={true}>Please Select Category</option>
                                        {/*<option selected>*/}
                                        {/*    {selected_category ? selected_category : "Category"}*/}
                                        {/*</option>*/}
                                        {category_details.map((element, index) => {
                                            return (/*selected_categoryId !== element.id &&*/ (
                                                    <option key={index} value={element.id}>{element.title}</option>)
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="col-12 col-md-3">
                                    <select onChange={onChangeBrand} className="form-select"
                                            aria-label="Default select example" value={selected_brandId || ""}>
                                        <option value="" disabled={true}>Please Select Brand</option>
                                        {/* <option selected>
                                    {selected_title ? selected_title : "Brand"}
                                </option>*/}
                                        {brand_details === null || !brand_details.length ? (
                                            <option value="0">Please Select Category</option>
                                        ) : (brand_details.map((element, index) => {
                                                return (/*selected_brandId !== element.id &&*/ (
                                                        // <option key={index} value={element.id} selected={selected_category === element.id}>{element.title}</option>
                                                        <option key={index} value={element.id}>{element.title}</option>
                                                    )
                                                );
                                            })
                                        )}
                                    </select>
                                </div>

                                <div className="col-12 col-md-3 ">
                                    <select
                                        onChange={handlePartTypeChange}
                                        className="form-select"
                                        aria-label="Default select example" value={part_type || ""}>
                                        <option value="" disabled={true}>Please Select Part Type</option>
                                        {partTypeList === null || !partTypeList.length ? (
                                            <option value="1">Please Select Brand</option>
                                        ) : (partTypeList.map((element, index) => {
                                                return (/*selected_brandId !== element.id &&*/ (
                                                        <option key={index}
                                                                value={element.title}>{element.title}</option>
                                                    )
                                                );
                                            })
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Tabskbar
                    part_type={part_type}
                    brand_id={brand_id}
                    brand_slug={brandSlug}
                    model_slug={modelSlug}
                    search={search}
                    setSearch={setSearch}
                    modelData={brand_details}
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    categoryId={categoryId}
                />
            </div>

            <Row className='m-0 mb-1'>
                <Col>
                    <img src={noitem} alt="banner" className='w-100'/>
                </Col>
            </Row>

        </div>
    );
}
