import React, { useEffect, useState,
    useRef } from "react";
  import { GoogleMap, LoadScript, Marker,Autocomplete  } from "@react-google-maps/api";
import { mapApiKey } from "../../api/constant";
import { getAddressFromLatLong } from "../LocationPicker/GeocodeFuction";
import { CircularProgress } from "@mui/material";
import { TextField, Paper, List, ListItem, ListItemText } from "@mui/material";

export function GoogleMapLocation({ addressMain, setAddressMain, isUpdate }) {
    const [map, setMap] = useState(null);
    const [center, setCenter] = useState({ lat: 23.233369, lng: 77.434263 });
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [address, setAddress] = useState({});
    const [geoDenied, setGeoDenied] = useState(false); // Track if geolocation is denied
    const [locationKey, setLocationKey] = useState(0); // Force re-render on location change
    const [searchText, setSearchText] = useState("");
    const [suggestions, setSuggestions] = useState([]);
      const autocompleteRef = useRef(null);
      const inputRef = useRef(null);

    useEffect(() => {
        if (isUpdate) {
            const lat = addressMain?.device_latitude || center.lat;
            const lng = addressMain?.device_longitude || center.lng;
            setCenter({ lat, lng });
            setSelectedLocation({ lat, lng });
            setLocationKey(prev => prev + 1); // Force map update
        } else {
            checkLocationPermission();
        }
    }, []);

    const checkLocationPermission = () => {
        if (!navigator.permissions) {
            getCurrentLocation(); // Fallback for unsupported browsers
            return;
        }

        navigator.permissions.query({ name: "geolocation" }).then((result) => {
            if (result.state === "granted" || result.state === "prompt") {
                getCurrentLocation();
            } else {
                setGeoDenied(true); // Show message if location is blocked
            }
        }).catch(() => setGeoDenied(true)); // Handle unsupported cases
    };

    const getCurrentLocation = () => {
        if (!navigator.geolocation) {
            console.error("Geolocation is not supported by this browser.");
            setGeoDenied(true);
            return;
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;
                setCenter({ lat, lng });
                setSelectedLocation({ lat, lng });
                setGeoDenied(false);
                setLocationKey(prev => prev + 1); // Force map update
            },
            (error) => {
                console.error("Error getting user location:", error);
                setGeoDenied(true);
            }
        );
    };

    const onLoad = (map) => setMap(map);
    const onUnmount = () => setMap(null);
    
    const handleMapClick = (event) => {
        setSelectedLocation({
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
        });
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        fetchAddressFromCoordinates(lat, lng);

    
    };

    useEffect(() => {
        if (selectedLocation?.lat && selectedLocation?.lng) getAddress();
    }, [selectedLocation]);

    const getAddress = async () => {
        const result = await getAddressFromLatLong(selectedLocation.lat, selectedLocation.lng);
        // const updatedAddress = {
        //     ...address,
        //     pincode: result?.pin_code || "",
        //     landmark: result?.address || "",
        //     name_city: result?.city || "",
        //     name_state: result?.state || "",
        //     device_latitude: selectedLocation.lat || "",
        //     device_longitude: selectedLocation.lng || "",
        // };
         // If it's an update, merge only the geolocation-related fields into addressMain
         const updatedAddress = isUpdate ? {
            ...addressMain, // Retain the existing address data from addressMain
            pincode: result?.pin_code || "",
            landmark: result?.address || "",
            name_city: result?.city || "",
            name_state: result?.state || "",
            device_latitude: selectedLocation.lat || "",
            device_longitude: selectedLocation.lng || "",
        } : {
                        // For add operation, fill out the address with the geolocation details

            pincode: result?.pin_code || "",
            landmark: result?.address || "",
            name_city: result?.city || "",
            name_state: result?.state || "",
            device_latitude: selectedLocation.lat || "",
            device_longitude: selectedLocation.lng || "",
        };
        setAddress(updatedAddress);
        setAddressMain(updatedAddress);
    };
        // console.log("address edit",address);
    //autosuggestion search logic
    const updateLocation = (lat, lng, formattedAddress, city, state, pincode) => {
        setSelectedLocation({ lat, lng });
        setCenter({ lat, lng });
      
        if (map) {
          map.panTo({ lat, lng });
          map.setZoom(15);
        }
      
        setSearchText(formattedAddress);
      
          console.log("📌 Final Address Data:", {
            lat, lng, formattedAddress, city, state, pincode
          });
      
          setAddressMain({
            device_latitude: lat,
            device_longitude: lng,
            landmark: formattedAddress,
            city,
            state,
            pincode,
          });
        };

        const fetchAddressFromCoordinates = (lat, lng) => {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ location: { lat, lng } }, (results, status) => {
              if (status === "OK" && results[0]) {
                const addressComponents = results[0].address_components;
                let city = "";
                let state = "";
                let pincode = "";
        
                console.log("📍 Address Components:", addressComponents);
        
                addressComponents.forEach((component) => {
                  if (component.types.includes("locality")) {
                    city = component.long_name;
                  }
                  if (component.types.includes("administrative_area_level_1")) {
                    state = component.long_name;
                  }
                  if (component.types.includes("postal_code")) {
                    pincode = component.long_name;
                  }
                });
        
                // Fallback to sublocality if locality is missing
                if (!city) {
                  const sublocality = addressComponents.find((comp) =>
                    comp.types.includes("sublocality_level_1")
                  );
                  if (sublocality) city = sublocality.long_name;
                }
        
                console.log(`✅ Extracted -> 🌆 City: ${city}, 🏛️ State: ${state}, 📮 Pincode: ${pincode}`);
        
                updateLocation(lat, lng, results[0].formatted_address, city, state, pincode);
              }
            });
          };
          const handleInputChange = (event) => {
            const input = event.target.value;
            setSearchText(input);
            setSuggestions([]);
        
            if (!autocompleteRef.current || !input.trim()) return;
        
            const autocompleteService = new window.google.maps.places.AutocompleteService();
            autocompleteService.getPlacePredictions({ input,componentRestrictions: { country: "IN" }, // Restrict search to India
            }, (predictions, status) => {
              if (status === "OK" && predictions) {
                setSuggestions(predictions);
              }
            });
          };
          const handleSuggestionClick = (suggestion) => {
            setSearchText(suggestion.description);
            setSuggestions([]);
        
            const placesService = new window.google.maps.places.PlacesService(map);
            placesService.getDetails({ placeId: suggestion.place_id }, (place, status) => {
              if (status === "OK" && place.geometry && place.geometry.location) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                fetchAddressFromCoordinates(lat, lng);
              }
            });
    };

    return (
        <>
          {/* Show message if location is blocked */}
          {/* {geoDenied && (
                <div style={{ padding: "10px", backgroundColor: "#ffdddd", color: "#d8000c", textAlign: "center", marginTop: "10px" }}>
                    <strong>⚠ Location Access Denied:</strong> Please enable location in your browser settings.
                </div>
            )}
            <LoadScript googleMapsApiKey={mapApiKey}
                loadingElement={<h1 className={'text-center'}><CircularProgress size={30} color={"inherit"} /></h1>}>
                <GoogleMap
                    key={locationKey} // Forces re-render when location changes
                    mapContainerStyle={{ height: "80vh", width: "100%" }}
                    center={center}
                    onClick={handleMapClick}
                    zoom={15}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                >
                    {selectedLocation && <Marker position={selectedLocation} />}
                 </GoogleMap>
            </LoadScript> */}


{geoDenied && (
        <div
          style={{
            padding: "10px",
            backgroundColor: "#ffdddd",
            color: "#d8000c",
            textAlign: "center",
            marginTop: "10px",
          }}
        >
          <strong>⚠ Location Access Denied:</strong> Please enable location in
          your browser settings.
        </div>
      )}

      <LoadScript googleMapsApiKey={mapApiKey} libraries={["places"]}>
        <div style={{ display: "flex", flexDirection: "column", padding: "10px", position: "relative" }}>
          <Autocomplete onLoad={(ref) => (autocompleteRef.current = ref)}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search location..."
              value={searchText}
              onChange={handleInputChange}
              inputRef={(input) => {
                if (input && autocompleteRef.current) {
                  autocompleteRef.current.input = input;
                }
                inputRef.current = input;
              }}
              style={{ marginBottom: "10px" }}
              inputProps={{ autoComplete: "off" }}
            />
          </Autocomplete>

          {suggestions.length > 0 && (
            <Paper
              style={{
                position: "absolute",
                top: inputRef.current ? inputRef.current.offsetHeight + 10 : "50px",
                left: 0,
                width: "100%",
                zIndex: 999,
                maxHeight: "200px",
                overflowY: "auto",
                backgroundColor: "white",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <List>
                {suggestions.map((suggestion, index) => (
                  <ListItem button key={index} onClick={() => handleSuggestionClick(suggestion)}>
                    <ListItemText primary={suggestion.description} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}

          <GoogleMap
            mapContainerStyle={{ height: "80vh", width: "100%" }}
            center={center}
            zoom={15}
            onLoad={onLoad}
            onClick={handleMapClick}
          >
            {selectedLocation && <Marker position={selectedLocation} />}
          </GoogleMap>
        </div>
            </LoadScript>

          
        </>
    );
}
