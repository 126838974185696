import React, { useEffect, useState } from "react";
import ByCard from "../ByParts/ByCard";
import useWindowSize from "../../Context/WindowSize";
// import "bootstrap/dist/js/bootstrap. .min"; // Ensure Bootstrap JS is imported

export default function Crouser({ brands, id, basePath }) {
    const [col, setCol] = useState(4);
    const [total, setTotal] = useState(5);
    const { width } = useWindowSize();
    const [brandDetails, setBrandDetails] = useState([]);
    const carouselServices = `carousel-${id}`;


    useEffect(() => {
        if (width < 768) {
            setTotal(2);
            setCol(4);
        } else if (width < 900) {
            setTotal(4);
            setCol(3);
        } else if (width === 1024) {  // Special case for 1024px
            setTotal(4);
            setCol(1);
        } else if (width < 1200) {
            setTotal(4);
            setCol(1);
        } else {
            setTotal(6);
            setCol(2);
        }
    }, [width]);

    useEffect(() => {
        setBrandDetails(brands || []);
    }, [brands]);

    useEffect(() => {
        // Manually initialize Bootstrap carousel (if auto-init fails)
        const carouselElement = document.querySelector(`#carousel-${id}`);
        if (carouselElement) {
            new window.bootstrap.Carousel(carouselElement);
        }
    }, []);

    const len = Math.ceil(brandDetails.length / total);

    return (
        <div id={`carousel-${id}`} className="carousel carousel-dark slide" data-bs-ride="carousel">
            {/* Carousel Indicators */}
            <div className="carousel-indicators">
                {Array.from({ length: len }).map((_, idx) => (
                    <button
                        key={`indicator-${id}-${idx}`}
                        type="button"
                        data-bs-target={`#carousel-${id}`}
                        data-bs-slide-to={idx}
                        className={idx === 0 ? "active" : ""}
                        aria-current={idx === 0 ? "true" : ""}
                        aria-label={`Slide ${idx + 1}`}
                    />
                ))}
            </div>

            {/* Carousel Inner */}
            <div className="carousel-inner carousal-upper">
                {Array.from({ length: len }).map((_, idx) => (
                    <div key={`carousel-item-${id}-${idx}`} className={`carousel-item ${idx === 0 ? "active" : ""}`}>
                        <div className="container">
                            <div className="row gy-5 text-center">
                                {brandDetails.slice(idx * total, (idx + 1) * total).map((brand, index) => (
                                  <div key={`brand-card-${id}-${index}`} className={`col-${col} d-flex justify-content-center align-items-center top-services`}>
                                        <ByCard image={brand.url_icon} slug={brand.slug} title={brand.title} baseUrl={basePath} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Carousel Controls with Unique IDs */}
            <button className="carousel-control-prev" type="button" data-bs-target={`#carousel-${id}`} data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target={`#carousel-${id}`} data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true" />
                <span className="visually-hidden">Next</span>
            </button>

            <style>{`
                 @media (max-width: 1024px) {
    .carousal-upper .carousel-item {
        height: unset;
    }

    .carousal-upper .row {
        display: flex;
        justify-content: center;
        gap: 15px;
    }

    .top-services {
        flex: 1 1 calc(25% - 15px); /* Ensures 4 items per row */
        max-width: 25%;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

     #${carouselServices} .carousel-control-prev,
    .carousel-control-next {
        width: 5%;
    }

     #${carouselServices}  .carousel-control-prev-icon,
    .carousel-control-next-icon {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        padding: 10px;
    }
}
    @media (max-width: 320px) {
    .carousal-upper .carousel-item {
        height: unset;
    }

    .carousal-upper .row {
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    .top-services {
        flex: 1 1 calc(50% - 10px); /* Ensures 2 items per row */
        max-width: 100%;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

     #${carouselServices} .carousel-control-prev,
    .carousel-control-next {
        width: 8%;
    }

     #${carouselServices} .carousel-control-prev-icon,
    .carousel-control-next-icon {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        padding: 8px;
    }
      @media (max-width: 425px) {
    .carousal-upper .carousel-item {
        height: unset;
    }

    .carousal-upper .row {
        display: flex;
        justify-content: center;
        flex-wrap: nowrap; /* Prevents items from wrapping */
        gap: 10px;
    }

    .top-services {
        flex: 0 0 calc(50% - 10px); /* Ensures exactly 2 items per row */
        max-width: 50%;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /* Use a valid ID selector */
    #${carouselServices}  .carousel-control-prev,
     #${carouselServices}  .carousel-control-next {
        width: 8%;
    }

     #${carouselServices}  .carousel-control-prev-icon,
     #${carouselServices}  .carousel-control-next-icon {
        border-radius: 50%;
        padding: 8px;
        /* background: none !important; */ /* Uncomment if needed */
    }
}


}


            }
            `}</style>
        </div>
    );
} 
