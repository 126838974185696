import { React, useEffect, useState } from "react";
import { part_list } from "../../api/general";
import ShopCard from "./ShopCard";
import SkeletonFile from "../OurServices/SkeletonFile";
import { Button } from "react-bootstrap";


export default function ProductDisplay({ part_type, model_id, brand_id, modelData, categoryId }) {
    const [product_details, setProduct_details] = useState([]);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [btnLoadMore, setBtnLoadMore] = useState("Load More");
    const [isMoreData, setIsMoreData] = useState(true);






    const getData = async () => {
        const inputData = { offset: offset };
        if (model_id) {
            inputData.model_id = model_id;
            inputData.model_true = true;
        }
        if (brand_id) {
            inputData.brand_id = brand_id;
            inputData.brand_true = true;
        }
        if (part_type) {
            inputData.part_type = part_type;
        } if (categoryId) {
            inputData.categoryId = categoryId;
        }

        setLoading(true)
        const { data } = await part_list(inputData);
        setLoading(false)

        if (!data.error) {
            // setProduct_details((prev) => [...new Set([...prev, ...data.data])]);
            setProduct_details(data.data);

            // console.log(product_details.length);
            if (data.data.length < 12) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
            }
        } else {
            setIsMoreData(false);
        }

        console.log(data);
    };


    useEffect(() => {
        setProduct_details([]);
        getData();
    }, [modelData, model_id, part_type]);


    const nextData = async () => {
        const { data } = await part_list({ part_type: part_type, model_id, model_true: true, offset: offset });
        // setProduct_details(data.data);
        // console.log(data.data);
        if (!data.error) {
            if (data.data.length < 12) {
                setIsMoreData(false);
            } else {
                setIsMoreData(true);
            }
            if (data.data.length) {
                setProduct_details((prev) => [...new Set([...prev, ...data.data])]);
                // setProduct_details((prev) => [...prev, ...data.data]);
            }
        } else {
            setIsMoreData(false);
        }
        setBtnLoadMore("Load More")
        // console.log(product_details)
        // console.log(product_details.length);
    };


    useEffect(() => {
        if (offset !== 0) {
            if (product_details && Array.isArray(product_details) && product_details.length > 0) {
                nextData();
            }
        }
    }, [offset])


    const handelInfiniteScroll = async () => {
        // try {
        //     if (
        //         window.innerHeight + document.documentElement.scrollTop + 1 >=
        //         (document.documentElement.scrollHeight)
        //     ) {

        setOffset((prev) => prev + 1)
        //     }
        // } catch (error) {
        //     // console.log(error);
        // }
    };

    useEffect(() => {
        // window.addEventListener("scroll", handelInfiniteScroll);
        // return () => window.removeEventListener("scroll", handelInfiniteScroll);
    }, []);


    return (
        <>
            <div className=" row g-3">
                {loading && <SkeletonFile />}
                {!loading ? (product_details === null || !product_details.length)
                    ? <div className="text-center fs-3 min-height">No Parts Found</div>
                    : product_details.map((element, index) => {
                        return (
                            <div key={element?.token || element?.id || index}
                                className="col-sm-6 col-md-4 col-lg-3  col-xl-2 /*my-3*/ d-flex justify-content-center align-item-center">
                                <ShopCard part={element} image={element?.url_image}
                                    price={element?.amount_price_customer}
                                    title={element?.title} slug={element?.slug} />
                            </div>
                        );
                    }) : ""}
                <div className="text-center">
                    {isMoreData && (
                        <Button type="submit" variant="" className="btn btn-primary3 my-3 "
                            onClick={handelInfiniteScroll}>{btnLoadMore}</Button>
                    )}

                </div>
            </div>

        </>
    );
}
