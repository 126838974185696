import React, {useEffect, useRef, useState} from "react";
import {
    FaEnvelopeOpen,
    FaFacebookF,
    FaGlobe,
    FaInstagram,
    FaLinkedinIn,
    FaMapMarkerAlt,
    FaPhoneAlt,
    FaPinterestP,
    FaTwitter,
    FaWhatsapp,
    FaYoutube
} from "react-icons/fa";
import {Link} from "react-router-dom";
import {appAddress, appEmail, appFooterLogo, appPhoneNumber, appTitleSub, appWebsite} from "../api/constant";
import {Avatar, Stack} from "@mui/material";
import {BiCopyright} from "react-icons/bi";
import {get_general_page_content} from "../api/general";
import {Col, Container, Row} from "react-bootstrap";
import banner from "./asset/banner.webp";
import {showAdBanners} from "../api/client";
import {useAuth, useNotification} from "../hooks";
import playStoreIcon from "../components/asset/play-store.webp"
import appleStoreIcon from "../components/asset/app-store.webp"
import {ApiCall} from "../api/HelpingMethod";

export default function Footer() {
    const {authInfo} = useAuth();
    const [footerPages, setFooterPages] = useState([])
    const {setShowLoginModel} = useNotification();

    
    const getFooterPageData = async () => {
        const {data} = await get_general_page_content({limit: 10, offset: 0, type: "footer"})
        setFooterPages(data)
        // console.log(data)
    };

    useEffect(() => {
        getFooterPageData();
    }, []);


    const [height, setHeight] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        setHeight(ref.current.clientHeight)
        getBannerAdCall();
        // console.log(height)
    }, [])
    const currentYear = new Date().getFullYear();
    // , width:"20px", height:"20px"
    const style = { color: "var(--primary)", marginRight: "0.8rem" }
    const styleSocialAvatar = { bgcolor: "var(--primary)", width: 30, height: 30 }
    const sizeSocialIcon = 15;
    const footerMenus = [
        {
            title: "Home",
            link: "/",
            show: "default"
        },
        // {
        //     title: "Booking",
        //     link: "/booking",
        // },
        // {
        //     title: "Part Purchase",
        //     link: "/product",
        // },
        {
            title: "Franchise",
            link: "/franchise",
            show: "default"
        }, {
            title: "Blogs",
            link: "/blog",
            show: "default"
        }, {
            title: "Quick Order",
            link: "/quick-entry",
            show: "dealer"
        }, {
            title: "Custom Part Request",
            link: "/part-request",
            show: "both"
        }, {
            title: "Contact Us",
            link: "/contact-us",
            show: "default",
        }, {
            // title: "Bulk Enquiry",
            title: "Become a Seller",
            link: "/become-partner",
            show: "default",
        }

    ]

    const [bannerFooterTop, setBannerFooterTop] = useState(null);

    const getBannerAdCall = async () => {
        await ApiCall('dealer/advertisement', {
            key_advertisement: "footer_top_banner"
        }).then((r) => {
            setBannerFooterTop(r?.data);
        });
    }
    const redirectBanner = (url) => {
        if (url) {
            window.open(url, '_blank');
        }
    }

    const deliveryBoyApp = 'https://play.google.com/store/apps/details?id=in.iserviceindia.partner';
    const dealerApp = "https://play.google.com/store/apps/details?id=in.iserviceindia.member"


    return (
        <footer ref={ref} className=" text-lg-start bg-dark text-muted d-print-none">

            {bannerFooterTop?.url_image && showAdBanners && <Row className='m-0'>
                <Col className="p-0 pointer">
                    <img onClick={() => redirectBanner(bannerFooterTop?.url_redirect)} src={bannerFooterTop?.url_image}
                        className='w-100 banner-image pointer' alt={'no image'} />
                </Col>
            </Row>}


            <section className="d-flex justify-content-center ">
                <div className="container   text-start mt-5">
                    <div className="row mt-3">
                        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                            <img className="logo-footer mb-3" src={appFooterLogo}
                                onError={(e) => e.target.src = banner} alt={"img"} />
                            {/*<h6 className=" fw-bold mb-4 text-white">
                                {appTitle}
                            </h6>*/}
                            <p className="text-white">
                                {appTitleSub}
                            </p>
                            <div className={'d-block text-center align-items-center d-flex '}>
                                <Stack direction="row" spacing={1} className={'mx-auto mx-md-0'}>
                                    <Avatar onClick={() => window.open("https://www.facebook.com/iservicebpl/")}
                                        className={'social-icon'} alt="FaFacebook"
                                        sx={styleSocialAvatar}><FaFacebookF size={sizeSocialIcon} /></Avatar>
                                    <Avatar onClick={() => window.open("https://www.instagram.com/iservice_india/")}
                                        className={'social-icon'} sx={styleSocialAvatar}
                                        alt="instagram"><FaInstagram size={sizeSocialIcon} /></Avatar>
                                    <Avatar onClick={() => window.open("https://twitter.com/iservice_india")}
                                        className={'social-icon'} sx={styleSocialAvatar}
                                        alt="twitter"><FaTwitter size={sizeSocialIcon} /></Avatar>
                                    <Avatar onClick={() => window.open("https://www.linkedin.com/company/iservice-india")}
                                        className={'social-icon'} sx={styleSocialAvatar} alt="linkedin"><FaLinkedinIn
                                            size={sizeSocialIcon} /></Avatar>
                                   {/* <Avatar onClick={() => window.open("https://www.linkedin.com/in/iservice-india/")}
                                        className={'social-icon'} sx={styleSocialAvatar} alt="Youtube"><FaLinkedinIn
                                            size={sizeSocialIcon} /></Avatar>*/}
                                    <Avatar onClick={() => window.open("https://wa.me/91" + appPhoneNumber + "/")}
                                        className={'social-icon'} sx={styleSocialAvatar} alt="whatsapp">
                                        <FaWhatsapp
                                            size={sizeSocialIcon} /></Avatar>
                                    <Avatar onClick={() => window.open("https://in.pinterest.com/iservice_india/")}
                                        className={'social-icon'} sx={styleSocialAvatar} alt="pinterest"><FaPinterestP
                                            size={sizeSocialIcon} /></Avatar>
                                    <Avatar onClick={() => window.open("https://www.youtube.com/@iServiceIndia")}
                                        className={'social-icon'} alt="youtube"
                                        sx={styleSocialAvatar}><FaYoutube size={sizeSocialIcon} /></Avatar>
                                    {/* <Avatar onClick={() => window.open("https://www.youtube.com/@iServiceIndia")}
                                            className={'social-icon'} alt="FaFaYoutube"
                                            sx={styleSocialAvatar}><FaAndroid size={sizeSocialIcon}/></Avatar> */}
                                </Stack>
                            </div>

                            <div className=" justify-content-center d-flex my-4">
                                <Avatar onClick={()=>window.open(dealerApp,"_blank")} alt="Play store" sx={{ width: "150px", height: "100%" }} variant="rounded" className={"m-1 pointer social-icon"} src={playStoreIcon} />
                                <Avatar alt="Apple store" sx={{ width: "150px", height: "100%" }} variant="rounded"
                                    className={"m-1"}
                                    src={appleStoreIcon} />
                            </div>


                        </div>

                        <div className="col-6 col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 text-white">
                            <h5 className="text-capitalize fw-bold text-white mb-4">Pages</h5>
                            {footerMenus && footerMenus.map((item, index) => {
                                let isShow = false;
                                let isLoginReq = false;
                                if (item?.show === 'both') {
                                    isShow = true;
                                    if (authInfo?.profile?.user_type) {
                                        isShow = true;
                                    } else {
                                        isLoginReq = true;
                                    }
                                } else if (item?.show === 'dealer') {
                                    if (authInfo?.profile?.user_type === 'dealer') {
                                        isShow = true;
                                    }
                                } else {
                                    isShow = true;
                                }
                                return (<p className="text-white" key={index}>
                                    {isLoginReq ?
                                        <span onClick={() => setShowLoginModel(true)}
                                            style={{ textDecoration: "none", color: "white" }}
                                            className="text-reset  text-hover"> {item?.title || "Menu"} </span>
                                        : (isShow &&
                                            <Link to={item?.link || "/"}
                                                style={{ textDecoration: "none", color: "white" }}
                                                className="text-reset  text-hover"> {item?.title || "Menu"} </Link>
                                        )}
                                </p>
                                );
                            })}


                            {/*{footerPages && Array.isArray(footerPages) && footerPages.length > 0 && (
                                <>
                                    {footerPages.slice(0, 1).map((pageFoot, index) => {
                                        return (

                                            <p key={"page-footer-" + (pageFoot.slug || index)}>
                                                <Link
                                                    to={pageFoot.slug === 'about' || pageFoot.slug === 'about-us' ? "/about" : "/page/" + (pageFoot?.slug) + "?slug=" + (pageFoot?.slug)}
                                                    style={{textDecoration: "none", color: "white"}}
                                                    className="text-reset">
                                                    {pageFoot?.title}
                                                </Link>
                                            </p>
                                        );
                                    })}
                                </>
                            )}*/}
                        </div>

                        {/* {footerPages && Array.isArray(footerPages) && footerPages.length > 0 && (
                            <div className="col-6 col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 text-white">
                                <h6 className="text-capitalize fw-bold text-white mb-4">&nbsp;</h6>
                                {footerPages.slice(0, 12).map((pageFoot, index) => {
                                    return (
                                        <p key={"page-footer-" + (pageFoot.slug || index)}>
                                            <Link to={"/page/" + (pageFoot?.slug)}
                                                style={{ textDecoration: "none", color: "white" }}
                                                className="text-reset text-hover">
                                                {pageFoot?.title}
                                            </Link>
                                        </p>
                                    );
                                })}

                            </div>
                        )} */}


                        {/* updated on 29-03-2025 starts here */}

                        {footerPages && Array.isArray(footerPages) && footerPages.length > 0 && (
                            <div className="col-6 col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 text-white">
                                <h6 className="text-capitalize fw-bold text-white mb-4">&nbsp;</h6>
                                {footerPages.slice(0, 12).map((pageFoot, index) => {
                                    const pageSlug = pageFoot?.slug;
                                    const pageUrl = pageSlug === "about" ? "/about" : `/page/${pageSlug}`;

                                    return (
                                        <p key={`page-footer-${pageSlug || index}`}>
                                            <Link to={pageUrl}
                                                style={{ textDecoration: "none", color: "white" }}
                                                className="text-reset text-hover">
                                                {pageFoot?.title}
                                            </Link>
                                        </p>
                                    );
                                })}
                            </div>
                        )}
                        {/* updated on 29-03-2025 ends here */}


                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 text-white">
                            <h5 className="text-capitalize fw-bold mb-4 text-white">Contact Us</h5>
                            <p className="text-white d-flex text-left"><FaPhoneAlt size={20}
                                style={style} />+91 {appPhoneNumber}
                            </p>
                            <p className={'d-flex text-left'}><FaMapMarkerAlt size={25}
                                style={style} /> {appAddress}</p>
                            <p className={'d-flex text-left'}><FaEnvelopeOpen size={20}
                                style={style} /> {appEmail}</p>
                            <p className={'d-flex text-left'}><FaGlobe size={20} style={style} /> {appWebsite}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/*<hr/>*/}
            <div className="text-center p-3 text-white">
                <BiCopyright style={style} /> {currentYear} iService by Service Jet Private Limited All right reserved
            </div>
            <hr />
            <div className="text-center  pb-2 text-white ">
                <Container>
                    <b>Note:</b> <small>All product names, logos, and brands are property of their respective owners.
                        All company, product and
                        service names used in this website are for identification purposes only. Use of these names, logos,
                        and
                        brands does not imply endorsement</small>
                </Container>
            </div>
        </footer>
    );
}
