import { React, useEffect, useState } from "react";
import { category_list, model, product_brand } from "../../api/general";
import { product_model } from "../../api/general";
import { book_service, book_service_form_image_upload } from "../../api/booking";
import { CircularProgress } from "@mui/material";
import { useAuth, useNotification } from "../../hooks";
import { CCol, CButton, CForm, CFormInput, CFormLabel, CFormSelect, CRow } from "@coreui/react";
import { useNavigate, useSearchParams } from "react-router-dom";

import DialogTempNotAvailable from "../DialogTempNotAvailable";
import { isAppTempDown } from "../../api/client";
import Select from 'react-select';
import { model_problem } from "../../api/general";
import { BsXLg } from "react-icons/bs";
import { Card, Col, Modal, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
export function BookingForm({
    data,
    setData,
    token,
    handeNext,
    setToken,
}) {




    // console.log("Data"+JSON.stringify(data))
    const [category_details, setCategory_details] = useState([]);
    const [selected_categoryId, setSelected_categoryId] = useState();
    const [selected_brandId, setSelected_brandId] = useState();
    const [selected_modelId, setSelected_modelId] = useState();
    const [selected_imei, setSelected_imei] = useState();
    const [model_details, setModel_details] = useState([]);
    const [brand_details, setBrand_details] = useState([]);
    const { authInfo } = useAuth();
    const [validated, setValidated] = useState(false)
    const [btnName, setButtonName] = useState('Next')
    const [btnDisable, setBtnDisable] = useState(false)
    const [loading, setLoading] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [slugCategory, setSlugCategory] = useState((searchParams.get("category-slug")));
    const [slugBrand, setSlugBrand] = useState((searchParams.get("brand-slug")));
    const [slugModel, setSlugModel] = useState((searchParams.get("model-slug")));
    const [loadingBtn, setBtnLoading] = useState(false)
    const { setOpenBackDrop } = useNotification();


    const [other, setOther] = useState();
    const [file, setFile] = useState([]);
    const [urlImages, setUrlImages] = useState([])

    const [model_problems, setModel_Problems] = useState([])
    const [dataList, setDataList] = useState([]);
    const [pageLoading, setPageLoading] = useState(false)
    const [show, setShow] = useState(false);
    const [modalImage, setModalImage] = useState(null)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShowImage = (url) => {
        setModalImage(url)
        handleShow()
    }



    const navigate = useNavigate();
    // setData(data)
    // console.log("prev data"+JSON.stringify(data))
    useEffect(() => {
        if (data) {
            setSelected_categoryId(data?.device_info?.category_id || 0);
            setSelected_brandId(data?.device_info?.brand_id || 0);
            setSelected_modelId(data?.device_info?.model_id || 0);
            setSelected_imei(data?.device_info?.device_serial_imei || selected_imei || "")

            var list = data?.device_info?.device_problem || "";
            if (typeof list === 'string' || list instanceof String) {
                var problems = list.split(',');
                if (problems && Array.isArray(problems)) {
                    setDataList(problems)
                } else {
                    setDataList([])
                }
            }

            setOther(data?.device_info?.device_problem_other)

            if (data?.device_info?.url_image_client) {
                setUrlImages(data?.device_info?.url_image_client)

            }
        }
    }, [data])
    useEffect(() => {
        let problems
        if (problems && Array.isArray(problems)) {
        }

    }, [])

    const handleSelect = (e) => {
        if (dataList.includes(e.target.value)) {
            const array = dataList.filter((ele) => {
                return ele !== e.target.value
            })
            setDataList(array);
        } else {
            setDataList([...dataList, e.target.value])
        }
    }

    const getCategories = async (inputData) => {
        setOpenBackDrop(true)
        const { data } = await category_list(inputData);
        if (data?.data && Array.isArray(data?.data)) {
            setCategory_details(data?.data);
        } else {
            if (data?.data?.id) setSelected_categoryId(data?.data?.id || 0);
        }
        setOpenBackDrop(false)

        return data;
    };
    const getBrands = async (inputData) => {
        const { data } = await product_brand(inputData);

        if (data?.data && Array.isArray(data?.data)) setBrand_details(data?.data || []);
        return data;
    };
    const getModels = async (inputData) => {
        setOpenBackDrop(true)
        const { data } = await product_model({ ...inputData, checkData: true });

        if (data?.data && Array.isArray(data?.data)) {

            setModel_details(data?.data);
        }
        setOpenBackDrop(false)

        return data;
    };

    const onLoadCall = async (inputData) => {
        setLoading(true);
        await getCategories({ is_service: "yes" });
        if (slugCategory && !selected_categoryId) {
            const category = await getCategories({ is_service: "yes", slug_category: slugCategory });
            const brands = await getBrands({ category_slug: slugCategory });
        }
        if (slugBrand && !selected_brandId) {
            const brand = await getBrands({ brand_slug: slugBrand });
            if (brand?.data) {
                // setBrand(brand?.data?.title);
                setSelected_brandId(brand?.data?.id);
            }
            const models = await getModels({ slug_brand: slugBrand });
            // console.log(models);
            if (models) {
                setModel_details(models?.data || []);
            }
        }
        if (slugModel && !selected_modelId) {
            setOpenBackDrop(true);
            const model = await getModels({ slug_model: slugModel });
            // console.log(model);
            if (model?.data) {
                // console.log(model?.data);
                // console.log(model?.data?.id);
                setSelected_modelId(model?.data?.id);
            }
            setOpenBackDrop(false);

        }
        setLoading(false);
    };

    useEffect(() => {
        onLoadCall();
        getCategories()
    }, []);

    const onCategoryChange = async (event) => {
        setSelected_categoryId(event.target.value);
        setSelected_modelId('')
        setSelected_brandId('')
        setModel_details([])
        // const { data } = await getBrands({ category_id: event.target.value });
    };
    const brandOptions = brand_details.map((brand) => ({
        value: brand.id,
        label: brand.title
    }));


    const [brandSearch, setBrandSearch] = useState("");
    const [modelSearch, setModelSearch] = useState("");
    useEffect(() => {
        if (selected_brandId && brand_details.length > 0) {
            const selectedBrand = brand_details.find(brand => brand.id === selected_brandId);
            if (selectedBrand) {
                setBrandSearch({ value: selectedBrand.id, label: selectedBrand.title });
            }
        }
    }, [selected_brandId, brand_details]);

    useEffect(() => {
        if (selected_modelId && model_details.length > 0) {
            const selectedModel = model_details.find(model => model.id === selected_modelId);
            if (selectedModel) {
                setModelSearch({ value: selectedModel.id, label: selectedModel.title });
            }
        }
    }, [selected_modelId, model_details]);

    useEffect(() => {
        if (data?.device_info?.brand_id) {
            const selectedBrand = brand_details.find(brand => brand.id === data.device_info.brand_id);
            if (selectedBrand) {
                setBrandSearch({ value: selectedBrand.id, label: selectedBrand.title });
            }
        }
        if (data?.device_info?.model_id) {
            const selectedModel = model_details.find(model => model.id === data.device_info.model_id);
            if (selectedModel) {
                setModelSearch({ value: selectedModel.id, label: selectedModel.title });
            }
        }
    }, [data, brand_details, model_details]);

    const handleBrandSearch = (selectedOption) => {
        setSelected_brandId(selectedOption.value);
        setBrandSearch(selectedOption);
    };

    const handleModelSearch = (selectedOption) => {
        setSelected_modelId(selectedOption.value);
        setModelSearch(selectedOption);
    };
    // Coverted End
    // Convert model data to react-select options
    const modelOptions = model_details.map((model) => ({
        value: model.id,
        label: model.title
    }));
    const onIChange = async (event) => {
        setSelected_imei(event.target.value);
        // setIemi(event.target.value)
    };

    const getBrandList = async () => {
        setOpenBackDrop(true)
        const { data } = await model({ category_id: selected_categoryId });
        setBrand_details(data?.data);
        setOpenBackDrop(false)

    }
    const getModelList = async () => {
        setOpenBackDrop(true)
        const { data } = await product_model({ brand_id: selected_brandId });
        // setBrand(event.target.selectedOptions[0].getAttribute('title'))
        setModel_details(data?.data || []);
        setOpenBackDrop(false)

    }
    useEffect(() => {
        if (selected_categoryId) getBrandList(selected_categoryId);
    }, [selected_categoryId])
    useEffect(() => {
        if (selected_brandId) getModelList(selected_brandId);
    }, [selected_brandId])

    const [locationInfo, setLocationInfo] = useState({
        // step: "device-problem",
         step: "device-info",
        login_user_id: authInfo.profile.id,
        login_user_type: authInfo.profile.user_type,
        token_order: token,
    });
    const { showError, showSuccess } = useNotification()
    const [notAvOpen, setNotAvOpen] = useState(false);
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isAppTempDown) {
            setNotAvOpen(true);
            return;
        }

        setButtonName("loading...");
        setBtnLoading(true)

        setBtnDisable(true);

        const form = event.currentTarget
        if (form.checkValidity() === false) {
            setButtonName("Next");
            setBtnLoading(true)

            event.stopPropagation();

        } else {
            const formData = new FormData();
            for (let key in locationInfo) {
                formData.append(key, locationInfo[key]);
            }

            if (token) {
                formData.append("token_order", token)
                formData.append("step", "device-info")
            }
            formData.append("brand_id", selected_brandId)
            formData.append("category_id", selected_categoryId)
            formData.append("model_id", selected_modelId)
            formData.append("device_serial_imei", selected_imei)
            formData.append("device_problem", dataList)
            formData.append("url_image_client", file[0])
            formData.append("device_problem_other", other)
            formData.append("from_page", "combine")

            const { data, error } = await book_service_form_image_upload(formData);
            setButtonName("Next");
            setBtnLoading(false)

            setBtnDisable(false);
            if (error || data.error) return showError(data.message);
            // console.log("Seting"+JSON.s)
            setData(data?.data)
            // console.log("Data"+JSON.stringify(data))

            // console.log("Data"+data?.token)
            navigate("/book-repair?token=" + data?.token + "&" + "step=" + data?.step)
            // showSuccess(data.message)
            setToken(data?.token)
            handeNext();
        }
        setButtonName("Next");
        setBtnLoading(false)

        setBtnDisable(false);
        setValidated(true);
    };
    const problemFun = async () => {
        try {
            setPageLoading(true); // Start loading
            const { data } = await model_problem({ id: authInfo?.profile.id });
            setModel_Problems(data?.data || []);
        } catch (error) {
            console.error("Error fetching problems:", error);
        } finally {
            setPageLoading(false); // Stop loading after fetch
        }
    };

    useEffect(() => {
        problemFun();
    }, []); // R
    const handleFileChange = (e) => {
        const filesArray = Array.from(e.target.files); // Convert FileList to an array
        setFile((e.target.files)); // Store the actual file objects

        const imageUrls = [];
        filesArray.forEach((file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                imageUrls.push(reader.result);
                if (imageUrls.length === filesArray.length) {
                    setUrlImages((prevImages) => [...prevImages, ...imageUrls]); // Append new images
                }
            };
        });
    };


    return (
        <>
            <DialogTempNotAvailable open={notAvOpen} setOpen={setNotAvOpen} />
            <div className="center-form container">

                <CForm className="needs-validation"
                    noValidate validated={validated} onSubmit={handleSubmit}>
                    <CRow className={"gy-3"}>
                        <CCol md={6}>
                            <CFormLabel htmlFor="select-category">Select Category</CFormLabel>
                            <CFormSelect id={'select-category'} aria-label="Default select example"
                                onChange={onCategoryChange} required={true} value={selected_categoryId || 0}>
                                <option value="0" disabled={true}>Please select Category</option>
                                {category_details && Array.isArray(category_details) ? category_details.map((element) => {
                                    return <option value={element.id} key={element.id}>{element.title}</option>;
                                }) : ""}
                            </CFormSelect>
                        </CCol>
                        <CCol md={6}>
                            <CFormLabel htmlFor="select-brand">Select Brand</CFormLabel>
                            <Select
                                id="select-brand"
                                options={brandOptions}
                                value={brandSearch} // This must be an object
                                onChange={handleBrandSearch}
                                placeholder="Search Brand..."
                            />
                        </CCol>

                        <CCol md={6} className="mt-4">
                            <CFormLabel htmlFor="select-model">Select Model</CFormLabel>
                            <Select
                                id="select-model"
                                options={modelOptions}
                                value={modelSearch}
                                onChange={handleModelSearch}
                                placeholder="Search Model..."
                                isDisabled={!selected_brandId} // Disable until a brand is selected
                            />
                        </CCol>
                        <CCol md={6} className="mt-4">
                            <CFormLabel htmlFor="imei_number">IMEI/Serial number (Optional)</CFormLabel>
                            <CFormInput id="imei_number" onChange={onIChange} value={selected_imei=="undefined"?"":selected_imei || ""} />
                        </CCol>

                        {/*  */}
                        <div className="center mt-5">
                            {/*<h2>What is the problem of the device ?</h2>*/}
                            <h5>What exactly is the issue with the device?</h5>
                            {/*<p>Please fill the following details about you product condition. i.e. problem, and images of your device.</p>*/}
                            <small>Please provide the following information about your product's condition:</small>
                        </div>
                        <div className="upContainer">
                            {/*<h6 className={'text-left'}>Select device problems:</h6>*/}
                            <div className="{/*firstSelectioncon*/} {/*selectionDate*/} mb-5">
                                {!pageLoading &&
                                    model_problems.map((element, idx) => {
                                        return (

                                            <CButton key={idx} id={idx} color="secondary" variant="outline"
                                                onClick={handleSelect}
                                                value={element.title}
                                                shape="rounded-pill"
                                                className={`selectable-btn ${dataList.includes(element.title) ? 'buttonStyle2' : ''}`}>{dataList.includes(element.title) ?
                                                    <FaCheck size={15} /> : ''} {element.title}</CButton>

                                        );
                                    })}
                            </div>
                        </div>
                        <div className="downContiner">
                            <CRow className={"g-3"}>
                                <CCol md={6}>
                                    <CFormLabel htmlFor="exampleDataList">Others</CFormLabel>
                                    <CFormInput list="datalistOptions"
                                        id="exampleDataList" value={ other=="undefined"?"":other || ""}
                                        onChange={(e) => setOther(e.target.value)}
                                        placeholder="Type Problem here" className={"py-2"} />

                                </CCol>
                                <CCol xs={12} md={6}>
                                    <CFormLabel htmlFor="formFileMultiple">Upload Picture (Optional)</CFormLabel>


                                    <CFormInput
                                        type="file"
                                        className="form-control"
                                        id="formFileMultiple"
                                        multiple
                                        onChange={handleFileChange}
                                    />

                                </CCol>
                            </CRow>
                            <CCol md={6}>


                                {urlImages.length > 0 && (
                                    <>
                                        <Row>
                                            <Col className="clientDetail mt-3">Product Images</Col>
                                        </Row>

                                        <Row>
                                            {urlImages.map((img, index) => (
                                                <Col xs={6} sm={3} key={index} className="mt-3">
                                                    <Card onClick={() => handleShowImage(img)} className="pointer">
                                                        <Card.Body className="p-2">
                                                            <Card.Img alt="client image" className="img-fluid" src={img} />
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </>
                                )}

                            </CCol>
                            {/* Modal for displaying full-size image */}
                            <Modal show={show} onHide={handleClose} size="lg" centered>
                                <Modal.Body className="booking-modal-box p-0">
                                    {modalImage && (
                                        <>
                                            <img
                                                className="d-flex justify-content-center mx-auto"
                                                style={{ height: "auto", width: "70%" }}
                                                src={modalImage}
                                                alt="Preview"
                                            />
                                            <div className="booking-modal-cros-btn text-end p-2">
                                                <BsXLg className="close-button" size={30} onClick={handleClose} />
                                            </div>
                                        </>
                                    )}
                                </Modal.Body>
                            </Modal>



                        </div>
                        {/*  */}
                        <CCol md={12} className={'mt-3'}>
                            <button className={"sign-up-button mx-auto " + (loadingBtn && 'disabled-button')}
                                disabled={loadingBtn}
                                onClick={handleSubmit}>
                                {loadingBtn && <CircularProgress size={16} color={"inherit"} />}&nbsp; {btnName}
                            </button>
                        </CCol>
                    </CRow>

                </CForm>


            </div>
        </>
    );
}
