import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    Accordion, AccordionActions, AccordionDetails, AccordionSummary,
    Alert,
    AlertTitle,
    CircularProgress,
    Fab,
    InputAdornment,
    TextareaAutosize,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {useEffect, useState} from "react";
import {address_state, get_data_by_url} from "../../api/general";
import {useAuth, useNotification} from "../../hooks";
import {Col, Row} from "react-bootstrap";
import {InputField} from "../InputFields/InputFieldHelper";
import AddressPickLocation from "../LocationPicker/AddressPickLocation";
import {GoogleMapLocation} from "../GoogleMapLocationPick/GoogleMapLocation";
import ReactGoogleMapPicker from "../GoogleMapLocationPick/ReactGoogleMapPicker";

export default function ModalAddressAdd({reloadData, setReloadData, place, defaultOpen}) {
    const [open, setOpen] = useState(defaultOpen || false);
    const [buttonPlace, setButtonPlace] = useState(place || "end");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const [addressStates, setAddressStates] = useState([]);
    const [addressCities, setAddressCities] = useState([]);
    const [showLocationPicker, setShowLocationPicker] = useState(true);
    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    useEffect(() => setButtonPlace(place), [place])
    const [isMobileValid, setIsMobileValid] = useState(true); // To track mobile validity
    const validateMobile = (mobileNumber) => {
        const mobilePattern = /^[6-9][0-9]{9}$/; // Starts with 7, 8, or 9 followed by 9 digits
        return mobilePattern.test(mobileNumber);
    };
    const {showError,} = useNotification();
    const {authInfo} = useAuth();
    const [btnSubmit, setBtnSubmit] = useState('Submit')
    const [messageError, setMessageError] = useState('')
    const [loading, setLoading] = useState(false)
    const stateAddress = {};
    const [address, setAddress] = useState({
        login_user_id: authInfo?.profile?.id || "",
        login_user_type: authInfo?.profile?.user_type || "",
        name: authInfo?.profile?.name || "",
        email: authInfo?.profile?.email || "",
        mobile: authInfo?.profile?.mobile || "",
        pincode: "",
        name_state: "",
        name_city: "",
        address: "",
        landmark: "",
        device_latitude: 0.00,
        device_longitude: 0.00,
        remark: ""
    });
    const handleSubmit = async (event) => {
        event.preventDefault();   
             // Check if mobile number is valid before submission

        if (!isMobileValid) {
            showError("Please enter a valid mobile number.");
            return; // Prevent form submission if mobile is invalid
        }
        if (!address?.address) {
            showError("Please fill your address to continue");
            return;
        } else if (!address?.name_city) {
            showError("Please fill your city name to continue");
            return;
        } else if (!address?.name_state) {
            showError("Please fill your state name to continue");
            return;
        } else {
            if (!address?.login_user_id || !address?.login_user_type) {
                console.warn("❌ Missing login_user_id or login_user_type, updating manually...");
                setAddress((prev) => {
                    const updatedAddress = {
                        ...prev,
                        login_user_id: authInfo?.profile?.id || "",
                        login_user_type: authInfo?.profile?.user_type || "",  // ✅ Ensuring login_user_type is set
                    };
                    console.log("Updated Address:", updatedAddress);
                    return updatedAddress;
                });
    
                // Wait for state update before making API call
                return;
            }
            setBtnSubmit("loading...");
            setLoading(true);
        try{
            const response = await get_data_by_url("dealer/address-update", address);
            setReloadData(true);
            setBtnSubmit("Submit");
            setLoading(false);

            if (response?.error === false) {
                // showSuccess(response?.message || "Address added successfully");
                handleClose();
                //console.log("response---", response)

            } else {
                showError(response?.message || "Error encounter");
            }
            setShowLocationPicker(true);
        } catch (error) {
            setLoading(false);
            showError("Error encountered while submitting the data");
        }
        }

    };
    const handleChange = (e) => {
        const {name, value} = e.target;
        // if (name === 'name_state') {
        //     getCityName(value).then(r => r);
        //     setAddress({...address, "name_city": ""});
        // }
        // console.log(e.target);
                // Validate mobile number when the 'mobile' field is changed

        if (name === 'mobile') {
            const isValid = validateMobile(value);
            setIsMobileValid(isValid); // Track validity of mobile number

            // if (!isValid) {
            //     showError("Please enter a valid Indian mobile number.");
            //     return; // Do not update state if validation fails
            // }
        }
        setAddress({...address, [name]: value});
    };


    return (
        <div>
            <button 
            type="button"  // Prevents accidental form submission
            className={"sign-up-button float-" + (buttonPlace || "end")} 
            onClick={handleClickOpen}
        >
            Add Address
        </button>
            {/* <Fab variant="extended" size="small" color="primary" onClick={handleClickOpen}>
                <FaTimes sx={{ mr: 3 }} />
                Cancel Order
            </Fab>*/}
            <Dialog open={open} onClose={handleClose}
                    fullScreen={fullScreen}
                    fullWidth
                    maxWidth="lg"
                // scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description">

                <DialogTitle>Add New Address
                    {!showLocationPicker && <Button type={'button'} variant={'contained'} color={'warning'}
                                                    className={'sign-up-button m-1 float-end'}
                                                    onClick={() => setShowLocationPicker(true)}>Pick
                        Location</Button>}
                </DialogTitle>

                <DialogContent>
                    {showLocationPicker ?
                        // <AddressPickLocation stateAddress={stateAddress} address={address} setAddress={setAddress}/>
                        <GoogleMapLocation addressMain={address} setAddressMain={setAddress} isUpdate={false}/>
                        // <ReactGoogleMapPicker addressMain={address} setAddressMain={setAddress}/>

                        : (<>
                                <Row className={'gx-3 gy-2'}>
                                    <Col md={12}>
                                        <InputField type={'text'} minLength={3} name={'name'}
                                                    label={'Name'} value={address?.name}
                                                    placeholder={'Enter your name'}
                                                    onChange={handleChange} required={true}
                                        />
                                        {/*<TextField variant="outlined" id="address-name" label="Name"  />*/}
                                    </Col>
                                    <Col md={6}>
                                        <InputField appendType={'start'} appendIcon={'+91'} type={'text'}
                                                    min={1000000000} max={9999999999} name={'mobile'}
                                                    label={'Mobile Number'}
                                                    placeholder={'Enter your mobile number'}
                                                    onChange={handleChange}
                                                    value={address?.mobile} required={true}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <InputField type={'text'} name={'email'}
                                                    label={'Email'} value={address?.email}
                                                    placeholder={'Your email address'}
                                                    onChange={handleChange} required={false}/>
                                    </Col>

                                    <Col md={9}>
                                        {/*<TextField className={'form-control w-100'} name={'address'} label={'Address'}
                                                           value={address?.address} variant="standard"
                                                           onChange={handleChange} required={true}/>*/}
                                        <InputField type={'textarea'} name={'address'} label={'Address'}
                                                    value={address?.address}
                                                    placeholder={'Please enter your address here. i.e. B.No.00, xyz nagar'}
                                                    onChange={handleChange} required={true}/>
                                    </Col>
                                    <Col md={3}>
                                        <InputField type={'number'} min={100000} max={999999} name={'pincode'}
                                                    label={'Pincode'} value={address?.pincode}
                                                    placeholder={'Your Area pincode'}
                                                    onChange={handleChange} required={true}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <InputField type={'text'} name={'landmark'} label={'Landmark'}
                                                    value={address?.landmark}
                                                    placeholder={'Please enter landmark'}
                                                    onChange={handleChange} required={true}/>
                                    </Col>

                                    <Col md={6}>
                                        <InputField type={'text'} name={'remark'}
                                                    label={'Remark (Any delivery instructions)'}
                                                    value={address?.remark}
                                                    placeholder={'Enter any remark?'}
                                                    onChange={handleChange} required={false}/>
                                    </Col>
                                    <Col md={6}>
                                        <InputField type={'text'} minLength={3} name={'name_city'}
                                                    label={'City'} value={address?.name_city}
                                                    placeholder={'Your city name'}
                                                    onChange={handleChange} required={true}
                                                    options={addressCities}/>
                                    </Col>
                                    <Col md={6}>
                                        <InputField type={'text'} minLength={3} name={'name_state'}
                                                    label={'State'} value={address?.name_state}
                                                    placeholder={'Your state name'}
                                                    onChange={handleChange}
                                                    required={true}
                                                    options={addressStates}/>
                                    </Col>
                                    {/*<Col md={6}>
                                        <InputField type={'number'} name={'device_latitude'}
                                                    label={'device_latitude'} value={address?.device_latitude}
                                                    placeholder={'Your Area device_latitude'}
                                                    onChange={handleChange}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <InputField type={'number'} name={'device_longitude'}
                                                    label={'device_longitude'} value={address?.device_longitude}
                                                    placeholder={'Your Area device_longitude'}
                                                    onChange={handleChange}
                                        />
                                    </Col>*/}
                                </Row>
                                <Row className={'mt-3'}>
                                    <Col className={'text-end'}>
                                        {/*<DialogActions>*/}
                                        <Button onClick={handleClose} variant={'outlined'}
                                                color={'inherit'} className={'m-1'}>Cancel</Button>
                                        <Button disabled={loading} variant={'contained'} color={'warning'}
                                                className={'sign-up-button m-1'} type={'submit'} onClick={handleSubmit}
                                        > {loading &&
                                            <CircularProgress size={16}
                                                              color={"inherit"}/>}&nbsp; {btnSubmit}</Button>
                                    </Col>
                                </Row>
                                {/*</form>*/}
                            </>
                        )}
                    <p>
                        <small className={'text-danger capitalize'}>{messageError}</small>
                    </p>
                </DialogContent>


                {showLocationPicker ?
                    <DialogActions>
                        <Button type={'button'} onClick={handleClose} variant={'outlined'}
                                color={'inherit'}>Cancel</Button>
                        <Button type={"button"} variant={'outlined'} color={'warning'} className={'sign-up-button'}
                                onClick={() => {
                                    setShowLocationPicker(false);
                                    // console.log(stateAddress);
                                    setAddress({...address, ...stateAddress})
                                }}>Confirm Location Or Next</Button>
                    </DialogActions> : ""}

            </Dialog>
        </div>
    )
        ;
}