import {React, useEffect, useState} from "react";
import Crouser from "./CrouserPartType";
import {BeatLoader} from "react-spinners";
import {Row} from "react-bootstrap";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";

import {ApiCall} from "../../api/HelpingMethod";
import {useAuth, useNotification} from "../../hooks";

export default function PartCategory() {
    // const [loading, setLoading] = useState(true);
     
   const {authInfo} = useAuth();
    const [partTypeList, setPartTypeList] = useState([])
     const [bodyData, setBodyData] = useState({
            "login_user_id": authInfo.profile.id,
            "login_user_type": authInfo.profile.user_type,
            "limit": 0,
            "offset": 0
        })
     const getPartType = async () => {
    
         const {data} = await ApiCall('dealer/part_type', bodyData)
        //  console.log("Getting data: "+JSON.stringify(data))
         setPartTypeList([...[{'id': 0, 'title': "All"}], ...data])
     
     }
 
     useEffect(() => {
         getPartType()
     }, [])
// console.log("Part data: " + data);

// console.log(partTypeList);


    return (
        <>
            {partTypeList && Array.isArray(partTypeList) && partTypeList.length > 0 && (
                <div className=" my-5 px-5">
                    {/* {loading && <div className="container">
                        <h1 className={`buy-parts-div text-dark my-5 mx-5`}>
                            <SkeletonFileGeneral height={20} total={1} col={12} mx={'auto'}/>
                        </h1>
                        <Row>
                            <SkeletonFileGeneral height={140} total={5} col={2} mx={'auto'}/>
                        </Row>
                    </div>}
                    <div className="container">
                        <div className="text-center">
                            {loading && <BeatLoader color="#ee610f" size={25}/>}
                        </div>
                    </div> */}
                    <div>
                        <h3 className={`text-dark mb-4`}>Category Wise Product</h3>
                        {/*<Crouser partTypeList={partTypeList} basePath={'/shop?category-slug=' + '&brand-slug='}/>*/}
                        <Crouser brands={partTypeList} basePath={'/product?category-slug=' + '&brand-slug='}/>
                    </div>
                </div>
            )}
        </>
    );
}
