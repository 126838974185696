import logo from "./../components/asset/logo-white.png"
// import logo from "./components/asset/logo-white.webp"
import logoDark from "./../components/asset/logo.webp"
import favicon from "./../components/asset/favicon.webp"

const authToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYjQxYjcyN2VkOTY5NGMzODQ1ZjM3ZDFmMTkwMjAyYzMxODY4YmU2MTc3OGI2ZjJlOGMwNjY2MzZkZjhhMGE5NzE0ZWE4ZGE2MTBlMjkxYWMiLCJpYXQiOjE2Nzc4MjI2MTEuMTk0MDYxLCJuYmYiOjE2Nzc4MjI2MTEuMTk0MDcsImV4cCI6MzMyMzQ3MzE0MTEuMTYwNjYsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.zTZvF547MhKLOm9sAfqs1Y5hRTpSziPc8TEAV_N_XnukXUoi3KxBHLl6J2avQm2vw_IvIw9Wmvjun8jZjF2xxRICx157cL12lw7TMruNqkynWhZ5k0V_FnP6KrcUO9mTOMR9gNj_mASpHDeMqFeFIJChPWxvd_1lslPaJ91om5FHFnhm8OzzRZXQweSFM9J0UO5t4UfixkWoG0xatubyeFkVp4GsYusoG4UGYFSGCj5aM2KqPmg6LvMIh6skJraV9ufSdj7mWMXCLoA8feSl40bvpHttfdaI709gb3ajyXMtksKt6fGILD8_kESxvP4N1HYdMGO49M2y8ASOHBV5d7OPzYl0PoiTtWSTnmhiJbflFsbczI8OGfYG0cubb8nSQDxRJQ51QReIwO7UCuxnEnDG8Gz7IKOg8ahnarcmBrW2zxr0hM1UmOeAsry9gB-Q8RABcB7fUPBMZN45r6oJqF5inr6qYKouS8_sd78ebUY-jAyYvSFyCL_uZ3lTDic4qXTtICf981GwSa6uGKcdR8UYrq0xbiU0N7RAAua2TF0aVfA6waMT8aCfjuvQA-ypk64YyydkfPr8QOUrqByuNA6OWgkkcdsKSUrD8V6dJvg3ZLEukS_lOTi5SZcvIpjaIQnVKpCgNcLVyqEz-e7ENEr81F0zepDfROlxCeehCpg"
const fetchHeader = {
    Authorization: "Bearer " + authToken,
    accept: "application/json",
};
const requiredInput = {
    device_type: "web",
    istoken: "ISABCDEFGH",
};

const appFooterLogo = logo;
const appLogoDark = logoDark;
const appFavicon = favicon;
const appTitle = "iService (Service Jet Pvt. Ltd.)";
const appTitleBlog = "iService India";
const appName = "iService (Service Jet Private Limited)";

const appTitleSub = "iService – Mobile, Laptop, Printer, Camera, Console,Repairs Service. Fastest Way to Repair Your Device.";
const appPhoneNumber = "9713300066";
//phone Number changed on 29-03-2025  
const appWebsite = "www.iserviceindia.in";
const appAddress = "1st Floor Jyoti Cineplex, Zone-1, M.P Nagar, Bhopal (M.P.) (462011)";
const appAddressStreet = "1st Floor Jyoti Cineplex, Zone-1, M.P Nagar";
const appAddressMain = "Bhopal, Madhya Pradesh, INDIA";
const appEmail = "info@iserviceindia.in";
//live
const mapApiKey = "AIzaSyBiSqaMR69Mxbh5KLAG-nR_ATB0QCi-hKU";


export {
    appTitleBlog,
    mapApiKey,
    authToken,
    fetchHeader,
    requiredInput,
    appFooterLogo,
    appTitle,
    appTitleSub,
    appPhoneNumber,
    appEmail,
    appWebsite,
    appAddress,
    appName,
    appLogoDark,
    appFavicon,
    appAddressMain,
    appAddressStreet
}