import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Modal, Button } from "react-bootstrap";
import UserTypeSelectCard from "./UserTypeSelectCard";
import EngineeringIcon from '@mui/icons-material/Engineering';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PersonIcon from '@mui/icons-material/Person';
import BecomeAPartnerForm from "./BecomeAPartnerForm";
import noitem from "../asset/Web Ad boost.webp";
import { HelmetCustomInclude } from "../HelmetCustomInclude";
import { ApiCall } from "../../api/HelpingMethod";
import { showAdBanners } from "../../api/client";

export default function BecomeAPartner() {
    const [pageTitle] = useState("Become A Partner");
    const [metaImageUrl] = useState(null);
    
    const [userType, setUserType] = useState("");
    const [userTypeObject, setUserTypeObject] = useState({});
    const [showModal, setShowModal] = useState(false);

    const sizeIconUserType = { width: "70px", height: "70px" };
    const userTypes = [
        { id: 2, title: "Dealer", description: <ul className={'text-start'}>
            <li>Wholesale Price</li>
            <li>Zero Investment</li>
            <li>Free CRM Dashboard</li>
            <li>Original part Support</li>
        </ul>, value: 'dealer', icon: <PersonIcon sx={sizeIconUserType} color={'warning'} /> },

        { id: 3, title: "Service Engineer", description: <ul className={'text-start'}>
            <li>High Profit Margin</li>
            <li>Call base Charges</li>
            <li>Freelance Job</li>
            <li>Training and Part Support</li>
        </ul>, value: 'engineer', icon: <EngineeringIcon sx={sizeIconUserType} color={'warning'} /> },

        { id: 4, title: "Sales Partner", description: <ul className={'text-start'}>
            <li>Cost effective solution</li>
            <li>Own Part Business</li>
            <li>Freelance Job</li>
            <li>Training and Part Support</li>
        </ul>, value: 'delivery_boy', icon: <DeliveryDiningIcon sx={sizeIconUserType} color={'warning'} /> },

        { id: 5, title: "Parts Wholesaler", description: <ul className={'text-start'}>
            <li>Cost effective solution</li>
            <li>1 Crore Business</li>
            <li>100% Cash no credit business</li>
            <li>Original part Support</li>
        </ul>, value: 'distributor', icon: <ManageAccountsIcon sx={sizeIconUserType} color={'warning'} /> },

        { id: 6, title: "Exclusive Service Center", description: <ul className={'text-start'}>
            <li>Cost effective solution</li>
            <li>Free Online CRM</li>
            <li>100% Cash no credit business</li>
            <li>Original part Support</li>
        </ul>, value: 'service_center', icon: <BusinessCenterIcon sx={sizeIconUserType} color={'warning'} /> }
    ];

    useEffect(() => {
        getBannerAdCall();
    }, []);

    const [bannerTop, setBannerTop] = useState(null);

    const getBannerAdCall = async () => {
        await ApiCall('dealer/advertisement', { key_advertisement: "home_become_a_partner_top_page" })
            .then((r) => setBannerTop(r?.data));
    };

    const redirectBanner = (url) => {
        if (url) window.open(url, '_blank');
    };

    const handleSelectUserType = (val) => {
        setUserTypeObject(val);
        setUserType(val.value);
        setShowModal(true);
    };

    return (
        <div>
            <HelmetCustomInclude url={"become-partner"} title={pageTitle} image={metaImageUrl} />

            <section>
                <div className="container-fluid mt-md-5 mt-1">
                    <div className="row">
                        <div className="col-md-12 mx-auto">
                            <div>
                                <div className="row text-center mb-4">
                                    <h1 className="col-md-12 "><span className={'color-primary'}>Become a Seller</span></h1>
                                    <h5>Are you looking for an exciting business opportunity?</h5>
                                </div>

                                {showAdBanners && bannerTop?.url_image && (
                                    <Row className='m-0'>
                                        <Col>
                                            <img 
                                                onClick={() => redirectBanner(bannerTop?.url_redirect)}
                                                src={bannerTop?.url_image || noitem}
                                                className='w-100 banner-image pointer'
                                                alt='no advertisement'
                                            />
                                        </Col>
                                    </Row>
                                )}

                                <div className="row ms-sm-4 me-sm-4 ms-1 me-1 mb-3 mt-3">
                                    <div className="col-md-12">
                                        <Col>
                                            <div className="row g-3">
                                                {userTypes.map((element, idx) => (
                                                    <div className="col-md" key={"usr-typ-" + idx}>
                                                        <UserTypeSelectCard
                                                            onClick={() => handleSelectUserType(element)}
                                                            data={element}
                                                            icon={element.icon}
                                                            isActive={element.value === userType}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Modal for Become A Partner Form */}
            <Modal 
                show={showModal} 
                onHide={() => setShowModal(false)} 
                centered 
                size="lg"  // Makes the modal larger
                className="custom-modal"
            >
                {/* <Modal.Header closeButton className="bg-primary text-white">
                    <Modal.Title>
                        <i className="bi bi-person-plus-fill me-2"></i> Become a Partner
                    </Modal.Title>
                </Modal.Header>
                 */}
                <Modal.Body className="p-4">
                    {/* <div className="text-center mb-3">
                        <h5 className="text-primary">{userTypeObject.title}</h5>
                        <p className="text-muted">{userTypeObject.description}</p>
                    </div> */}

                    {/* Scrollable Form */}
                    <div className="modal-form ">
                        <BecomeAPartnerForm userType={userType} userTypeObject={userTypeObject} />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
            <style>{`
            /* Custom Modal Styling */
                .custom-modal .modal-content {
                    border-radius: 12px;
                    overflow: hidden;
                }

                /* Header Styling */
                .custom-modal .modal-header {
                    font-weight: bold;
                    border-bottom: 2px solid #fff;
                }

                /* Scrollable Form */
                .modal-form {
                    max-height: 700px;
                    overflow-y: auto;
                    padding: 10px;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    background-color: #f9f9f9;
                }

            `}</style>
        </div>
    );
}
