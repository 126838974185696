import React, {useEffect, useState} from "react";
import CartProduct from "./CartProduct";
import {cart, cartAction} from "../../api/cart";
import {useAuth, useNotification} from "../../hooks";
import MoreButton from "../HomePage/MoreButton";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import {Alert, Skeleton} from "@mui/material";
import DialogTempNotAvailable from "../DialogTempNotAvailable";
import {isAppTempDown} from "../../api/client";
import banner from "../asset/banner.webp";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import IncludeCartSummery from "./IncludeCartSummery";

export default function CartMain() {
    const [loading, setLoading] = useState(false);
    const [cart_details, setCart_details] = useState([]);
    const [whole_details, setWhole_details] = useState([]);
    const [updated, setUpdated] = useState(false);
    const {showError, setOpenBackDrop} = useNotification();
    const {authInfo} = useAuth();
    const navigate = useNavigate();
    const [productNotAvailable, setProductNotAvailable] = useState(false);
    const [notAvOpen, setNotAvOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [tokenPart, setTokenPart] = useState(searchParams.get("token_part") || null);
    const [quantityChange, setQuantityChange] = useState(false);
    const [caetAction, setCartAction] = useState(false);

    const [amountDeliveryCharge, setAmountDeliveryCharge] = useState(0);
    const [symbolCurrency, setSymbolCurrency] = useState("₹");


    useEffect(() => {
        document.title = 'Shopping Cart | iService - Innovation Service';
    }, [])

    const getData = async ({token_part, coupon, couponTrue}) => {
        if (!caetAction) {
            setLoading(true);
        }

        let inpDt = {
            id: authInfo?.profile.id,
            user_type: authInfo?.profile.user_type,
            coupon: coupon,
            couponTrue: couponTrue
        }
        if (token_part) {
            inpDt.token_part = token_part;
        }
        const {data} = await cart(inpDt);
        // console.log(data);
        if (data.error) {
            showError(data.message);
        } else {
            if (data?.data?.parts) await setCart_details(data?.data?.parts);
            if (data?.data) await setWhole_details(data?.data);
            setSymbolCurrency(data?.data?.amount_currency_symbol || data?.data?.amount_currency || symbolCurrency || "₹");
            if (authInfo?.profile?.user_type === 'dealer') {
                setAmountDeliveryCharge(data?.data?.amount_delivery_charge_dealer || data?.data?.amount_delivery_charge || 0)
            } else {
                setAmountDeliveryCharge(data?.data?.amount_delivery_charge_customer || data?.data?.amount_delivery_charge || 0)
            }
        }
        setLoading(false);

        return data;
        // console.log(whole_details);
    };

    useEffect(() => {
        getData({token_part: tokenPart, coupon: "", couponTrue: false});
    }, [updated, tokenPart]);

    useEffect(() => {
        if (!authInfo?.profile?.id) {
            showError("Please login to continue.");
            navigate("/auth");
        }
    })
    console.log("cart_details",cart_details);
    const handelRemove = async ({part_id}) => {
        setOpenBackDrop(true);
        const data = await cartAction({
            actionType: "remove_from_cart",
            part_id: part_id,
            id: authInfo.profile.id,
            user_type: authInfo.profile.user_type,
        });
        setUpdated(!updated);
        if (data.data.error) {
            showError(data.data.message);
        } else {
            // showSuccess(data.data.message);
        }
        setOpenBackDrop(false);
    };

    const handelIncrease = async ({cart_id}) => {
        setQuantityChange(true);
        setCartAction(true);
        const data = await cartAction({
            actionType: "quantity_increase",
            cart_part_id: cart_id,
            id: authInfo.profile.id,
            user_type: authInfo.profile.user_type,
        });

        if (data.data.error) {
            showError(data.data.message);
            setUpdated(!updated);
        } else {
            // showSuccess(data.data.message);
            setUpdated(!updated);
        }
        setQuantityChange(false);

    };

    const handelDecrease = async ({cart_id}) => {
        setQuantityChange(true);
        setCartAction(true);
        const data = await cartAction({
            actionType: "quantity_decrease",
            cart_part_id: cart_id,
            id: authInfo.profile.id,
            user_type: authInfo.profile.user_type,
        });
        if (data.data.error) {
            showError(data.data.message);
            setUpdated(!updated);
        } else {
            // showSuccess(data.data.message);

            setUpdated(!updated);
        }
        setQuantityChange(false);
    };

    const redirectToBookService = async () => {
        // navigate("/shop");
        navigate("/product");
    };

    if (!authInfo.profile.id) return "Please login first";

    const signDiscount = <b className={"text-success"} style={{fontSize: "20px"}}>-</b>;
    const signCharge = <b className={"color-primary"} style={{fontSize: "20px"}}>+</b>;
    
    return (
        <div>
            {/* <Backdrop sx={{color: 'var(--primary)', zIndex: (theme) => theme.zIndex.drawer + 1}} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop> */}
            <div className="ourservices">
                <h3 className=" mx-5 servicesHeading">Purchase Cart</h3><p className="mx-5 subHeading"></p>
            </div>
            <section>
                <div className="container py-md-5 py-sm-3 py-1">
                    {loading && <Container>
                        <Row>
                            <b className="product-info-heading col-lg-3"><Skeleton height={50}/></b>
                            <div className="col-lg-12"/>
                            <Col lg={8}>
                                <Row>
                                    <SkeletonFileGeneral height={200} total={2} col={12}/>
                                </Row>
                            </Col>
                            <Col lg={4}>
                                <Row>
                                    <SkeletonFileGeneral height={200} total={1} col={12}/>
                                </Row>
                            </Col>
                        </Row>
                    </Container>}
                    <div className="row d-flex justify-content-center my-2">
                        {!loading && (!cart_details || !Array.isArray(cart_details) || !cart_details.length ? "" :
                            <h5 className="mb-0 mx-3 product-info-heading col-lg-12">Cart Products</h5>)}
                        <div className="col-lg-8">

                            {!loading && (!cart_details || !Array.isArray(cart_details) || !cart_details.length ? (
                                <>
                                    <img className="no-item d-flex align-item-center justify-content-center mx-4"
                                         src="download.svg" alt={'cart empty'} onError={(e) => e.target.src = banner}/>
                                    <div className="text-center servicesCardHeading fs-2">Your Cart is Empty</div>
                                    <div className="d-flex align-item-center justify-content-center my-4">
                                        <a onClick={redirectToBookService}
                                           style={{textDecoration: "none", color: "white"}}>
                                            <MoreButton>Shop Now</MoreButton>
                                        </a>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {cart_details.map((element, index) => {
                                        return (<div className="container" key={element?.token || index}>
                                            <div className="row my-3">
                                                <CartProduct
                                                    setProductNotAvailable={setProductNotAvailable}
                                                    product={element.part}
                                                    handelRemove={handelRemove}
                                                    handelDecrease={handelDecrease}
                                                    handelIncrease={handelIncrease}
                                                    quantityChange={quantityChange}
                                                />
                                            </div>
                                        </div>);
                                    })}
                                </>
                            ))}
                        </div>
                        {!loading && cart_details && Array.isArray(cart_details) && cart_details.length ? (
                            <div className="col-lg-4 my-3">
                                <IncludeCartSummery user={authInfo?.profile} data={whole_details}
                                                    amountDeliveryCharge={amountDeliveryCharge}
                                                    symbolCurrency={symbolCurrency}/>
                                <div className="card mb-4 cart-summary">
                                    <div className="card-body pb-2 text-center">

                                        {/* <button onClick={handelCheckout} className="payment-button text-center my-4">
                                            Proceed to Payment
                                        </button>*/}
                                        <button
                                            onClick={() => navigate("/cart-checkout?token_part=" + (tokenPart || ""))}
                                            className="payment-button text-center my-4">
                                            Checkout
                                        </button>

                                        {/*<IntegrationRazorPay
                                            setNotAvOpen={setNotAvOpen}
                                            locationInfo={locationInfo}
                                            setPayment={setPayment}
                                            handelCheckout={handelCheckout}
                                            paymentMode={paymentMode}
                                            amount_total={(authInfo?.profile?.user_type === 'dealer' ? whole_details.amount_grand_total_dealer : whole_details.amount_grand_total_customer)}
                                            amount_currency={"INR"}
                                            order={whole_details}
                                            user={authInfo?.profile}/>*/}
                                        {isAppTempDown &&
                                        <Alert severity="warning">Sorry! This service is temporally not
                                            available</Alert>
                                        }

                                        {productNotAvailable && (
                                            <>
                                                {/*{cart_details?.error_messages ? cart_details?.error_messages.map((message) => {*/}
                                                {/*    return <Alert severity="warning">{message}</Alert>*/}
                                                {/*}) : (*/}
                                                <Alert severity="warning">
                                                    Some products were not available. If you are going to proceed,
                                                    then these products will not be checking out.
                                                </Alert>
                                                {/*)}*/}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </section>
            <DialogTempNotAvailable open={notAvOpen} setOpen={setNotAvOpen}/>
        </div>
    );
}
