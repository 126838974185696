import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {CircularProgress, Rating} from "@mui/material";
import {useAuth, useNotification} from "../../../hooks";
import {Col, Container, Form, Row} from "react-bootstrap";
import useMediaQuery from '@mui/material/useMediaQuery';
import {styled, useTheme} from '@mui/material/styles';
import {ApiCall} from '../../../api/HelpingMethod';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';



const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ef5d00',
    },
    '& .MuiRating-iconHover': {
        color: '#ef5d00',
    },
});


export default function ProductReviewModal({token_ref, ref_type, reviewFor, tokenReviewFor}) {
console.log(tokenReviewFor);
    const { showError, showSuccess } = useNotification();
    const [btnCancel, setBtnCancel] = useState('Add Review')
    const [messageError, setMessageError] = useState('')
    
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [value, setValue] = useState(0);
    const {authInfo} = useAuth();
    const [loading, setLoading] = useState(false)

    const [addReviewBody, setAddReviewBody] = useState({
        "login_user_id": authInfo.profile.id,
        "login_user_type": authInfo.profile.user_type,
        "ref_type": ref_type || "part",
        "token_ref": token_ref,
        "count_rating": value,
        // "review_for": reviewFor||'order',
        // "token_review_for": tokenReviewFor||"",
        "message": ""
    })

    // console.log("partsInfo---======", token_part);


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

  

    // console.log("authInfo---",authInfo)

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddReviewBody({ ...addReviewBody, [name]: value });
    };


    const addReview = async (event) => {
        setLoading(true);
        let inputBody = {...addReviewBody};
        if (reviewFor) inputBody.review_for = reviewFor || 'order';
        if (tokenReviewFor) inputBody.token_review_for = tokenReviewFor || "";
        // setBtnCancel("loading...");
        console.log(inputBody);
        const response = await ApiCall("dealer/user_review_update", inputBody)
        // console.log("response---->>", response);

        // setBtnCancel('Add Review')
        setLoading(false);

        if (response?.error === true) {
            setMessageError(response?.message);
            return showError(response?.message);
        } else {
            setMessageError(response?.message);
            // await getUsers();
            showSuccess(response?.message);
        }

        handleClose();
        setValue(0)
    };


    // console.log("addReviewBody---", addReviewBody)


    return (
        <div>
            {/* <Button className={'sign-up-button'} onClick={handleClickOpen}>
                Cancel Order
            </Button>*/}
            {/*<button className="sign-up-button"
                    onClick={handleClickOpen}>Review and Rate
            </button>*/}
            {/* <Fab variant="extended" size="small" color="primary" onClick={handleClickOpen}>
                <FaTimes sx={{ mr: 3 }} />
                Cancel Order
            </Fab>*/}
            <span onClick={handleClickOpen} className={'m-3 cart-price-text-red '} style={{ cursor: "pointer" }}>Write review</span>
            
            <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
                <DialogTitle>Add Your Review</DialogTitle>
                <DialogContent>
                    <DialogContentText className={'mb-3'}>

                        <Container>
                            <Row className=" mt-2 h3">
                                <Col className='review-list'>
                                    {/* <Rating
                                       size='large'
                                        name="simple-controlled"
                                        value={value}
                                        onChange={(event, newValue) => {
                                            // console.log("newValue---", newValue)
                                            setValue(newValue);
                                            addReviewBody.count_rating = newValue
                                        }}
                                    /> */}


                                    <StyledRating
                                        name="customized-color"
                                        icon={< StarIcon  sx={{ fontSize: 50 }} />}
                                        emptyIcon={<StarBorderIcon sx={{ fontSize: 50 }} />}
                                        value={value}
                                        onChange={(event, newValue) => { 
                                            setValue(newValue);
                                            addReviewBody.count_rating = newValue
                                        }}
                                    />

                                </Col>
                            </Row>
                            <Row>
                                <Form>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Add Review</Form.Label>
                                        <Form.Control onChange={handleChange} as="textarea"
                                            value={addReviewBody?.message} name='message' rows={3} />
                                    </Form.Group>
                                </Form>
                            </Row>
                        </Container>


                    </DialogContentText>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant={'outlined'} color={'inherit'}>Cancel</Button>
                    <Button disabled={loading} variant={'contained'} color={'warning'} className={'sign-up-button'}
                        onClick={addReview}>{loading && <CircularProgress size={16} color={"inherit"} />}&nbsp; {btnCancel}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}