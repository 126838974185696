import useRazorpay from "react-razorpay";
import { appAddress, appTitle } from "../../api/constant";
import logo from "../asset/logo.webp";
import React, { useEffect, useState } from "react";
import { get_data_by_url } from "../../api/general";
import { useNotification } from "../../hooks";
import { isAppPaymentOn } from "../../api/client";
import { CircularProgress, Modal, Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import navigate


export default function IntegrationRazorPay({
    addressId,
    locationInfo,
    setNotAvOpen,
    setPayment,
    handelCheckout,
    paymentMode,
    order,
    user,
    amount_total,
    amount_currency,
    loadingMain
}) {
    const [Razorpay] = useRazorpay();
    const { showError } = useNotification();
    const [address, setAddress] = useState(locationInfo);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [codPopup, setCodPopup] = useState(false);
    const navigate = useNavigate(); // Initialize navigate function
    const [orderToken, setOrderToken] = useState(null);

    useEffect(() => {
        setAddress(locationInfo);
    }, [locationInfo]);

    const capture_payment = async (paymentResponse) => {
        const inputData = {
            login_user_id: user?.id || "",
            login_user_type: user?.user_type || "",
            address_id: addressId,
            amount: amount_total,
            amount_currency: amount_currency,
            txn_order_id: paymentResponse?.razorpay_order_id ?? paymentResponse?.razorpay_payment_id ?? "",
            txn_ref: paymentResponse?.razorpay_payment_id,
            razorpay_order_id: paymentResponse?.razorpay_order_id ?? paymentResponse?.razorpay_payment_id ?? "",
            razorpay_payment_id: paymentResponse?.razorpay_payment_id ?? "",
            razorpay_payment_signature: paymentResponse?.razorpay_signature ?? "",
            remark: "",
            payment_source: paymentMode,
        };

        try {
            const response = await get_data_by_url("dealer/payment-update", inputData);
            if (response?.data && typeof setPayment === "function") {
                setPayment(response?.data);
            }
            return response?.data;
        } catch (error) {
            console.error("capture_payment() Error:", error);
            showError("Payment processing failed. Please try again.");
            return null;
        }
    };

    const loadRazorpayScript = () => {
        const script = document.createElement("script");
        script.src = `https://checkout.razorpay.com/v1/checkout.js`;
        document.body.appendChild(script);
    };

    const handlePayment = async () => {
        setLoadingBtn(true);
        try {
        if (paymentMode === "cod") {
            // console.log("COD Payment Selected");

            const response = await handelCheckout(null, "cod"); // Ensure order is created

            if (response?.data?.token) {
                setOrderToken(response.data.token); // Store token for redirection
                
                // 🔹 Show modal only when the order is confirmed
                setCodPopup(true);
            } 
        }

            if (paymentMode === "wallet") {
                handelCheckout(null, "wallet");
                return;
            }

            if (paymentMode === "razorpay") {
                if (!isAppPaymentOn) {
                    setNotAvOpen(true);
                    return;
                }

                loadRazorpayScript();

                const options = {
                    key: "rzp_live_JiqQPEKp9QCeKq",
                    amount: amount_total * 100,
                    currency: amount_currency || "INR",
                    name: appTitle || "iService",
                    description: `Payment For ${order?.title || "1234"}`,
                    image: logo || "",
                    handler: async function (response) {
                        const payment = await capture_payment(response);
                        if (payment) {
                            handelCheckout(payment);
                        }
                    },
                    prefill: {
                        name: user?.name || " ",
                        email: user?.email || " ",
                        contact: user?.mobile || " ",
                    },
                    notes: {
                        address: appAddress || "",
                    },
                    theme: {
                        color: "#ef5d00",
                    },
                };

                const rzp1 = new Razorpay(options);
                rzp1.on("payment.failed", function () {
                    showError("Payment failed, Try again");
                });

                rzp1.open();
                return;
            }

            // showError("Please select a payment method");
        } catch (error) {
            console.error("handlePayment() Error:", error);
            showError("Something went wrong. Please try again.");
        } finally {
            setLoadingBtn(false);
        }
    };

    return (
        <>
            <button
                disabled={loadingBtn}
                onClick={handlePayment}
                className={"payment-button text-center my-4 " + (loadingBtn && "disabled-button")}
            >
                {loadingBtn && <CircularProgress size={16} color={"inherit"} />} &nbsp; Proceed to Payment
            </button>

            {/* COD Confirmation Modal */}
           
         <Modal open={codPopup} onClose={() => setCodPopup(false)}>
            <Box
                sx={{
                p: 3,
                backgroundColor: 'white',
                width: 400,
                margin: 'auto',
                mt: 10,
                borderRadius: 2,
                textAlign: 'center',
                }}
            >
                {/* Custom Circled Tick */}
                <Box
                sx={{
                    width: 80,
                    height: 80,
                    borderRadius: '50%',
                    border: '3px solid #4caf50',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 2,
                    mx: 'auto', // Center horizontally
                }}
                >
                <Typography sx={{ fontSize: 40, color: '#4caf50' }}>✔</Typography>
                </Box>

                {/* Modal Title */}
                <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Order Created
                </Typography>

                {/* Success Message */}
                <Typography sx={{ mb: 2 }}>
                Thank you for your purchase. Your order has been created successfully.
                </Typography>

                {/* OK Button */}
                <Button
                variant="contained"
                sx={{
                    mt: 2,
                    backgroundColor: '#ef5d00',
                    '&:hover': { backgroundColor: '#d45000' },
                }}
                onClick={() => {
                    setCodPopup(false); // Close modal
                    if (orderToken) {
                        navigate(`/order-complete?order=${orderToken}`, { replace: true });
                    }
                
                }}
                >
                OK
                </Button>
            </Box>
        </Modal>
        </>
    );
}
