import React from "react";
import { NeedBody } from "./NeedBody";
import { ProblemPageBody } from "./ProblemPageBody";
import { DeviceInfoBlock } from "./DeviceInfoDetailBlock";
export function NeedService({ data, setData, token, handleBack, handeNext }) {
    return (
        <>
            <div className="center">
                {/*<h2>When do you need the service ?</h2>*/}
                {/*<p> Select date and time  for visit schedule for book your service. </p>*/}
                <h5>To book your service</h5>
                {/*To book your service,*/} <small>choose a date and time from the visit schedule.</small>  
                
                <DeviceInfoBlock />
                <ProblemPageBody data={data}  />
                <NeedBody data={data} setData={setData} token={token} handleBack={handleBack} handeNext={handeNext}  />
            </div>
        </>
    );
}
