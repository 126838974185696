import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { FaQuoteLeft, FaStar, FaRegStar } from "react-icons/fa";
import { fetchReviews } from "../../api/general";
import { useAuth } from "../../hooks";

export default function ReviewTestimonial() {
  const [testimonials, setTestimonials] = useState([]);
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);
  const [itemsPerSlide, setItemsPerSlide] = useState(3);
  const { authInfo } = useAuth();

  // console.log("auth", authInfo?.profile.id);
  // console.log("profileuser_type", authInfo?.profile.user_type);
  // console.log("testiomalias", testimonials);

  useEffect(() => {
    const loadReviews = async () => {
      try {
        const { data } = await fetchReviews({
          id: authInfo?.profile.id,
          user_type: authInfo?.profile.user_type,
        });
        setTestimonials(data?.data || []);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    loadReviews();

    const updateItemsPerSlide = () => {
      if (window.innerWidth < 768) {
        setItemsPerSlide(1); // Mobile: 1 card per slide
      } else if (window.innerWidth >= 768 && window.innerWidth <= 991) {
        setItemsPerSlide(2); // Tablet: 2 cards per slide
      } else {
        setItemsPerSlide(3); // Desktop: 3 cards per slide
      }
    };
    updateItemsPerSlide();

    window.addEventListener("resize", updateItemsPerSlide);
    return () => window.removeEventListener("resize", updateItemsPerSlide);
  }, []);

  const chunkArray = (arr, size) => {
    return arr.reduce(
      (acc, _, i) => (i % size === 0 ? [...acc, arr.slice(i, i + size)] : acc),
      []
    );
  };
  if (testimonials.length === 0) {
    return null;
  }

  return (
    <Container className="position-relative review-box">
      <h3 className="text-center mb-4">Hear From Our Customers</h3>
      <div
        id="carouselExampleInterval"
        className="carousel slide position-relative"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {testimonials.length > 0 ? (
            chunkArray(testimonials, itemsPerSlide).map((group, index) => (
              <div
                className={`carousel-item ${
                  index === 0 ? "active" : ""
                } reviewdiv`}
                key={index}
              >
                <Row className="justify-content-center">
                  {group.map((testimonial) => (
                    <Col
                      key={testimonial.id}
                      sm={12}
                      md={6}
                      lg={4}
                      className="mb-3"
                    >
                      <ReviewCard
                        testimonial={testimonial}
                        onSeeMore={setSelectedTestimonial}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            ))
          ) : (
            <p className="text-center">No reviews available.</p>
          )}
        </div>

        {/* Previous & Next Buttons */}
        {/* Previous & Next Buttons */}
        <button
          className="carousel-control-prev custom-carousel-btn"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon prev-button"
            id="prev-button"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>

        <button
          className="carousel-control-next custom-carousel-btn"
          type="button"
          data-bs-target="#carouselExampleInterval"
          data-bs-slide="next"
          
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
            id="next-button" 
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* Modal for Full Review */}
      {/* Modal for Full Review */}
      {selectedTestimonial && (
        <Modal show onHide={() => setSelectedTestimonial(null)} centered>
          <Modal.Header closeButton>
            <Modal.Title>{selectedTestimonial.user_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex justify-content-center">
              {[...Array(5)].map((_, i) =>
                i < selectedTestimonial.count_rating ? (
                  <FaStar key={i} className="text-warning" />
                ) : (
                  <FaRegStar key={i} className="text-warning" />
                )
              )}
            </div>
            <FaQuoteLeft className="pe-2 text-primary" />
            <p
              className="text-muted text-wrap"
              style={{ wordBreak: "break-word", whiteSpace: "pre-line" }}
            >
              {selectedTestimonial.message}
            </p>
          </Modal.Body>
        </Modal>
      )}

      {/* CSS for Responsiveness & Fixing Overflow Issues */}
      <style>
        {`
          /* Adjusted Container with Left & Right Margin */
          .review-box {
            max-width: 90%;
            margin: auto;
            padding: 20px;
          }

           /* Custom Carousel Buttons */
           .custom-carousel-btn .carousel-control-prev-icon,
           .custom-carousel-btn .carousel-control-next-icon {
           filter: invert(1); /* Converts white to black */
           background-color: rgba(0, 0, 0, 0.5); /* Adds a semi-transparent background */
           border-radius: 50%; /* Makes it rounded */
           padding: 10px; /* Increases button size */
           }

          .custom-carousel-btn {
           width: auto; /* Prevents full-height button */
          }
          #prev-button {
    top: 209%;
    position: absolute;
    margin-left: -217%;
}
    #next-button {
    top: 209%;
    position: absolute;
    margin-right: -217%;
}
          /* Positioning of Previous & Next Buttons */
          .custom-carousel-btn {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 45px;
            height: 45px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 1 !important;
            transition: background-color 0.3s ease-in-out;
          }

          .custom-carousel-btn {
          text-decoration: none !important;
          color:black;
          }
          .review-card {
          min-height: 190px; /* Adjust height as needed */
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          }
          .review-card button {
          margin-top: 5px;
          }

          .carousel-control-prev {
            left: -67px;
             top: 69%; /* Adjusted for better visibility */
          }

          .carousel-control-next {
            right: -68px;
             top: 69%; /* Adjusted for better visibility */
          }

          /* Prevent Overflowing Content */
          .review-card p {
            white-space: normal;
            word-wrap: break-word;
            overflow-wrap: break-word;
            max-width: 100%;
            margin-bottom: 8px;
          }

          /* Adjust for mobile view */
          @media (max-width: 768px) {
            .review-box {
              max-width: 80%;
              padding: 15px;
              margin-bottom: 50px;
            }

            .carousel-item.reviewdiv {
              height: auto !important;
              min-height: 150px;
            }

            .custom-carousel-btn {
              width: 35px;
              height: 35px;
            }

            .carousel-control-prev {
              left: -38px;
              top: 47%
             
            }

            .carousel-control-next {
              right: -38px;
               top: 47%
            }

            .review-card {
              font-size: 14px;
              padding: 15px;
              margin: auto;
              display: flex;
              flex-direction: column;
              align-items: center;
            }

            .modal-content {
  width: 85%;
  max-width: 360px;
  margin: auto;
}

.modal-body {
  font-size: 14px;
  max-height: 50vh;
  overflow-y: auto;
  padding: 10px;
  white-space: pre-line;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

          }
        `}
      </style>
    </Container>
  );
}

const ReviewCard = ({ testimonial, onSeeMore }) => {
  const MAX_LENGTH = 80;
  const message = testimonial.message;
  return (
    <div className="border rounded shadow-sm bg-white review-card text-center p-4">
      <p className="text-muted">
        <FaQuoteLeft className="pe-2 text-primary" />
        {message.length > MAX_LENGTH
          ? `${message.substring(0, MAX_LENGTH)}...`
          : message}
      </p>

      {message.length > MAX_LENGTH && (
        <Button
          variant="link"
          className="p-0 text-primary"
          onClick={() => onSeeMore(testimonial)}
        >
          See More
        </Button>
      )}

      <h5 className="text-dark">{testimonial.user_name}</h5>
      <div className="d-flex justify-content-center">
        {[...Array(5)].map((_, i) =>
          i < testimonial.count_rating ? (
            <FaStar key={i} className="text-warning" />
          ) : (
            <FaRegStar key={i} className="text-warning" />
          )
        )}
      </div>
    </div>
  );
};
