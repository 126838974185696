import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ByCard from "../ByParts/ByCard";
import useWindowSize from "../../Context/WindowSize";
import banner from "../asset/banner.webp";
import { styled, Tooltip, tooltipClasses, Zoom } from "@mui/material";

export default function Crouser({ brands, id, basePath }) {
    const [col, setCol] = useState(4);
    const [total, setTotal] = useState(5);
    const [margin, setMargin] = useState(3);
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const defaultImage = banner;

    useEffect(() => {
        if (width < 576) {
            setTotal(2);
            setCol(6);
            setMargin(0);
        } else if (width < 768) {
            setTotal(3);
            setCol(4);
            setMargin(0);
        } else if (width < 900) {
            setTotal(4);
            setCol(3);
            setMargin(0);
        } else if (width < 1200) {
            setTotal(5);
            setCol(2);
            setMargin(2);
        } else if (width < 1400) {
            setTotal(6);
            setCol(2);
            setMargin(2);
        } else {
            setTotal(6);
            setCol(2);
            setMargin(0);
        }
    }, [width]);

    const [brand_details, setBrands_details] = useState([]);

    useEffect(() => {
        if (brands) {
            setBrands_details(brands);
        }
    }, [brands]);

    const filteredBrands = brand_details.filter(
        (element) => element?.title?.toLowerCase() !== "all"
    );

    const slides = [];
    for (let i = 0; i < filteredBrands.length; i += total) {
        slides.push(filteredBrands.slice(i, i + total));
    }

    const handleTitleClick = (element) => {
        navigate(`/product`, { state: { homePart: element } });
    };

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} arrow TransitionComponent={Zoom} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));

    return (
        <div id={`carouselExampleDark-${id}`} className="carousel carousel-dark slide catTypeProMainDiv" data-bs-ride="carousel">
            <div className="carousel-indicators">
                {slides.map((_, idx) => (
                    <button
                        key={idx}
                        type="button"
                        className={idx === 0 ? "active" : ""}
                        data-bs-target={`#carouselExampleDark-${id}`}
                        data-bs-slide-to={idx}
                        aria-current={idx === 0 ? "true" : ""}
                        aria-label={`Slide ${idx + 1}`}
                    />
                ))}
            </div>

            <div className="carousel-inner catTypeProDiv">
                {slides.map((slide, idxx) => (
                    <div className={`mt-1 carousel-item ${idxx === 0 ? "active" : ""}`} key={idxx}>
                        <div className="containerX">
                            <div className="row gy-3 mx-3 text-center">
                                {slide.map((element, idx) => (
                                    <div key={idx} className={`col-${col} d-flex justify-content-center align-items-center mx-auto top-catTypePro`}>
                                        <LightTooltip title={element?.title || ""} placement="top">
                                            <div
                                                className="card shadow d-flex flex-column align-items-center catTypeProCard"
                                                onClick={() => handleTitleClick(element)}
                                            >
                                                <img
                                                    src={element?.url_image || defaultImage}
                                                    alt="Image Not Found"
                                                    className="card-img-top img-fluid"
                                                />
                                                <h6 className="catTypeProtitle">{element?.title}</h6>
                                            </div>
                                        </LightTooltip>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <button className="carousel-control-prev" type="button" data-bs-target={`#carouselExampleDark-${id}`} data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target={`#carouselExampleDark-${id}`} data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>

            <style>
                {`
                .carousel-indicators {
                    position: absolute;
                    bottom: -40px;
                }

                .carousel-control-prev, .carousel-control-next {
                    top: 40%;
                    transform: translateY(-10%);
                    width: auto;
                }
                    .carousel-control-next {
                    right: -22px !important;
                    top: 40% !important;
                }

                .carousel-control-prev {
                    left: -13px !important;
                    top: 40% !important;
                }


                .carousel-control-prev-icon,
                .carousel-control-next-icon {
                    filter: brightness(0) invert(1);
                }

                .catTypeProCard {
                    width: 100%;
                    max-width: 180px;
                    height: 190px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding: 10px;
                    border-radius: 8px;
                    transition: all 0.3s ease;
                    background: #fff;
                }

                .catTypeProCard img {
                    height: 120px;
                    width: 100%;
                    max-width: 160px;
                    object-fit: contain;
                    transition: transform 0.3s ease;
                }

                .catTypeProCard:hover {
                
                    border: 2px solid #ef5d00;
                    transform: translateY(-3px);
                    cursor: pointer;
                    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
                }
                        
                .catTypeProtitle {
                    font-size: 14px;
                    font-weight: 600;
                    color: #333;
                    width: 100%;
                    text-align: center;
                    margin-top: 8px;
                    overflow-wrap: break-word; 
                    white-space: normal; 
                    word-wrap: break-word;
                }


                @media (max-width: 768px) { 
                    .catTypeProtitle {
                        font-size: 12px;
                        white-space: normal;
                    }
                    .carousel-control-next {
                        right: -6px !important;
                        top: 23% !important;
                    }

                    .carousel-control-prev {
                        left:-6px !important;
                        top: 23% !important;
                    }
                    .carousel-indicators {
                        display: none !important;
                    }    	
                }

                @media (min-width: 768px) and (max-width: 1024px) {

                    .catTypeProDiv .carousel-item {
                        height: 220px;
                    }

                }

                @media (min-width: 481px) and (max-width: 767px) {

                } 
                
                @media (min-width: 320px) and (max-width: 480px) {
                    .catTypeProCard {
                        max-width: 140px;
                        height: 100px;
                    }    height: 90px;
                     
                    .catTypeProtitle {
                        font-size: 11px;
                    }
                    .carousel-indicators {
                        display: none !important;
                    } 
                    .carousel-control-next {
                        right: 8px !important;
                        top: 40% !important;
                    }

                    .carousel-control-prev {
                        left: 8px !important;
                        top: 40% !important;
                    }

                    .catTypeProCard { 
                        height: auto;
                    }
                    .catTypeProCard img {
                        height: 65px;
                    } 

                    .catTypeProDiv .carousel-item {
                        height: 131px!important;
                    } 
                        

                }  

                `}
            </style>
        </div>
    );
}
