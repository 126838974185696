import React from 'react'
import {Col, Container, Row} from "react-bootstrap";
import FooterFeaturedBlockCard from "./FooterFeaturedBlockCard";
import {FaAward, FaMobileAlt, FaTags, FaTruckMoving, FaUserCog} from "react-icons/fa";


export default function FooterFeaturedBlock({colXS, colSM, colMd, colLg, colXL}) {
    const data = [
        {
            icon: <FaTags size={30} color="orange"/>,
            // title: "Best prices & offers",
            // titleSub: "Best prices and offers daily",
            title: "Don't Miss Out",
            titleSub: "Best prices and offers",
        }, {
            icon: <FaTruckMoving size={30} color="orange"/>,
            title: "Fast delivery",
            titleSub: "24/7 amazing services",
        }, {
            icon: <FaAward size={30} color="orange"/>,
            title: "Great daily deal",
            titleSub: "When you sign up",
        }, {
            icon: <FaUserCog size={30} color="orange"/>,
            title: "Certified Engineer",
            titleSub: "Experienced engineer",
        }, {
            icon: <FaMobileAlt size={30} color="orange"/>,
            title: "Quality Parts",
            titleSub: "100% parts quality",
        }
    ]
    return (
        <Container fluid className={"my-5 px-5"}>
            <Row className={"g-3"} xs={{cols: colXS || "1"}} sm={{cols: colSM || "1"}} md={{cols: colMd || "3"}}
                 lg={{cols: colLg || "4"}} xl={colXL || "5"}>
                {data.map((value, index) => {
                    return (
                        <Col key={index}>
                            <FooterFeaturedBlockCard icon={value?.icon} title={value?.title}
                                                     titleSub={value?.titleSub}/>
                        </Col>);
                })}
            </Row>
        </Container>
    )
}
