import { React, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { part_list } from "../../api/general";
import { get_bookings } from "../../api/booking";
import { useAuth, useNotification } from "../../hooks";
import SimilarProducts from "./SimilarProducts";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import { Row, Container } from "react-bootstrap";
import { FaCheckDouble, FaTimes } from "react-icons/fa";
import { Modal, Box, Typography, Button } from "@mui/material";

export default function OrderBanner() {
    const { state } = useLocation();
    const [loading, setLoading] = useState(true);
    const [part_details, setPart_details] = useState([]);
    const [searchParams] = useSearchParams();
    const [orderToken, setOrderToken] = useState(searchParams.get("order"));
    const [order, setOrder] = useState({});
    const { authInfo } = useAuth();
    const navigate = useNavigate();
    const { showError } = useNotification();

    const [showModal, setShowModal] = useState(false); // State for modal visibility

    useEffect(() => {
        document.title = "Order Success | iService - Innovation Service";
    }, []);

    const getOrderDetail = async () => {
        if (orderToken) {
            setLoading(true);
            const { data, error } = await get_bookings({
                id: authInfo.profile?.id,
                user_type: authInfo.profile?.user_type,
                token_order: orderToken,
            });

            if (data?.data) {
                setOrder(data.data);
                await getData(data?.data?.order_parts[0]?.part?.brand_id);
                
                // 🔹 Show modal only if order is successful
                if (data?.data?.status === "pending" || data?.data?.status_payment === "success") {
                    setShowModal(true);
                }
            }
            
            setLoading(false);
        } else {
            showError("Undefined token");
            navigate("/");
        }
    };

    const getData = async (brand_id) => {
        const { data } = await part_list({
            brand_id: brand_id || state.id,
            brand_true: true,
        });
        setPart_details(data.data);
    };

    useEffect(() => {
        getOrderDetail();
        window.scrollTo(0, 0);
    }, [orderToken]);

    return (
        <div>
            <div className="ourservices">
                <div className="container">
                    {loading && (
                        <>
                            <Row>
                                <SkeletonFileGeneral height={30} total={1} col={7} />
                            </Row>
                            <Row>
                                <SkeletonFileGeneral height={20} total={1} col={12} />
                            </Row>
                        </>
                    )}

                    {!loading &&
                        (order && (order?.status === "pending" || order?.status_payment === "success") ? (
                            <>
                                <h3 className="servicesHeading text-success">
                                    <FaCheckDouble color={"success"} size={40} /> Thank you for your order
                                </h3>
                                <p className="subHeading">
                                    Your order completed successfully and your order number{" "}
                                    <b className={"text-uppercase"}>#{orderToken}</b>. You can also view your order in the
                                    booking section or{" "}
                                    <Link to={"/booking-details/" + orderToken + "?order=" + orderToken}>Click Here</Link>.
                                </p>
                            </>
                        ) : (
                            <>
                                <h3 className="servicesHeading text-danger">
                                    <FaTimes color={"danger"} size={50} /> Order unable to complete, Payment failed or
                                    cancelled.
                                </h3>
                                <p className="subHeading">
                                    Your order could not be processed. Payment was either canceled or could not be
                                    completed. Please place your order again if you would like to continue.
                                </p>
                                <p className="subHeading">
                                    Please check orders if your payment succeeded and this message is still showing. If
                                    the order is not showing in your order history, contact admin for support.
                                </p>
                            </>
                        ))}
                </div>
            </div>

            {loading && (
                <div className="container my-5">
                    <div className="row">
                        <SkeletonFileGeneral height={250} total={6} col={2} />
                    </div>
                </div>
            )}

            <SimilarProducts data={part_details} />

            {/* 🔹 Order Confirmation Modal */}
            <Modal open={showModal} onClose={() => setShowModal(false)}>
                <Box
                    sx={{
                        p: 3,
                        backgroundColor: "white",
                        width: 400,
                        margin: "auto",
                        mt: 10,
                        borderRadius: 2,
                        textAlign: "center",
                    }}
                >
                    <Box
                        sx={{
                            width: 80,
                            height: 80,
                            borderRadius: "50%",
                            border: "3px solid #4caf50",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mb: 2,
                            mx: "auto",
                        }}
                    >
                        <Typography sx={{ fontSize: 40, color: "#4caf50" }}>✔</Typography>
                    </Box>

                    <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
                        Order Confirmed
                    </Typography>

                    <Typography sx={{ mb: 2 }}>
                        Thank you for your purchase! Your order has been successfully placed.
                    </Typography>

                    <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 3 }}>
            {/* View Order Button (Redirects) */}
            <Button
                variant="contained"
                sx={{
                    backgroundColor: "#ef5d00",
                    "&:hover": { backgroundColor: "#d45000" },
                }}
                onClick={() => {
                    setShowModal(false);
                    navigate(`/booking-details/${orderToken}?order=${orderToken}`, { replace: true });
                }}
            >
                View Order
            </Button>

            {/* Stay Here Button (Closes Modal) */}
            <Button
                variant="outlined"
                sx={{
                    color: "#333",
                    borderColor: "#bbb",
                    "&:hover": { borderColor: "#888" },
                }}
                onClick={() => setShowModal(false)}
            >
              Close
            </Button>
        </Box>
                </Box>
            </Modal>
        </div>
    );
}
