import React, { useEffect, useState } from "react";
import { Col, Container,  Row } from "react-bootstrap";
import { ApiCall } from "../../api/HelpingMethod";
import { useAuth } from "../../hooks";
import SkeletonFileGeneral from "../../hooks/SkeletonFileGeneral";
import ModalAddressAdd from "../Cart/ModalAddressAdd";
// import { Avatar, Button, CircularProgress } from "@mui/material";
// import { BsCheckCircleFill, BsExclamationCircle, BsFillEnvelopeFill, BsFillGeoAltFill, BsPerson, BsPersonFill, BsTelephoneFill, BsTrash } from "react-icons/bs";
// import { Link } from "react-router-dom";
// import { FaCheckCircle } from "react-icons/fa";
// import { FcApproval } from "react-icons/fc";
// import { get_data_by_url } from "../../api/general";
import AddressCard from "./AddressCard";

const AddressList = () => {
    const { authInfo } = useAuth();
    const [cartAddress, setCartAddress] = useState([])
    const [loading, setLoading] = useState(false);
    const [updated, setUpdated] = useState(false);
    // const [showModal, setShowModal] = useState(false);

    const [addressBody] = useState({
        "login_user_id": authInfo?.profile?.id,
        "login_user_type": authInfo?.profile?.user_type,
        "limit": 10,
        "offset": 0
    })
    const getCartAddress = async (e) => {
        setLoading(true);
        const result = await ApiCall("/dealer/address", addressBody)
     //   console.log("result---",result)
        setCartAddress(result?.data);

        setLoading(false);
        setUpdated(false);

    }

    useEffect(() => {
        getCartAddress()
    }, [updated])





    return (
        <>


            <Container className="p-0" fluid>
                <Row className="h5 ms-1 mt-4 mb-2">
                    <Col>Addresses</Col>
                    <Col>
                        <ModalAddressAdd reloadData={updated} setReloadData={setUpdated} />
                    </Col>

                </Row>

                {loading && <Row className="mt-3"> <SkeletonFileGeneral height={80} col={12} total={5} /> </Row>}
                <Row className={''} >
                    {!loading && cartAddress && Array.isArray(cartAddress) && cartAddress.map((address, index) => {
                        return (

                            <AddressCard setReloadData={setUpdated} address={address} index={index} key={address?.token || address?.id} />

                        )
                    })}
                </Row>
                {!(cartAddress?.length) && !loading && <Row>
    <div style={{ marginBottom: "235px" }} className="fw-bold text-danger ">Addresses Are Not Availble</div>
</Row>}

            </Container >

        </>
    )
}
export default AddressList;