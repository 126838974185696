import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Form,
    InputGroup,
    Navbar,
    Offcanvas,
    Row
} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from "./asset/logo.webp"
// import { category_list } from "../api/general";
// mayu code
import { category_list, product_search } from "../api/general"; // mayu changes: imported product_search
// eOf mayu code 
import SearchIcon from '@mui/icons-material/Search';
import "./styles/AuthNav.css";
import banner from "./asset/banner.webp";
import { Avatar, Badge } from "@mui/material";
import { useAuth } from "../hooks";
import SignUpButton from "./SignUpButton";
import SignButton from "./SignButton";
import { ShoppingCart } from "@mui/icons-material";
import { UseScroll } from "./UseScroll";
import Navs from "./Navs";
import UserDrop from "./UserDrop";
import Notification from "./Notification";
import { cart } from "../api/cart";
import { FaSearch } from "react-icons/fa";
// 17-02-2025

const Header = ({ }) => {
    const pathArray = window.location.pathname.split("/");
    const [categoryList, setCategoryList] = useState('All');
    const [searchText, setSearchText] = useState("");
    const [selectedCategorySlug, setSelectedCategorySlug] = useState('All');
    const [selectedCategory, setSelectedCategory] = useState('All'); 
    const [isLogin, setIsLogin] = useState(false);
    const { authInfo } = useAuth();
    const [countCart, setCountCart] = useState(0);
    const { scrollDirection } = UseScroll();
    const [isActiveMenu, setActiveMenu] = useState('home');
    const [show, setShow] = useState(false);
    // mayu code
    const [selectedCategoryID, setSelectedCategoryID] = useState('All');
    const [searchSuggestions, setSearchSuggestions] = useState([]); //  state for suggestions
    const dropdownRef = useRef(null); // Reference for the dropdown
    // eOf mayu code

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (pathArray[1]) {
            setActiveMenu(pathArray[1]);
        } else {
            setActiveMenu('home');
        }
        // console.log(pathArray)
    }, [pathArray, authInfo]);

    useEffect(() => {
        if (authInfo?.profile && Object.keys(authInfo?.profile).length > 0) {
            setIsLogin(true);
        } else {
            setIsLogin(false);
        }
    }, [authInfo, isLogin])


    useEffect(() => {
        const getUsers = async () => {
            const { data } = await category_list({ is_part: "yes" });
            setCategoryList(data.data);
        };
        getUsers();


    }, []);


    const getCartCount = async () => {
        const { data } = await cart({
            id: authInfo?.profile.id,
            user_type: authInfo?.profile.user_type
        });
        const countst = data?.data?.cart_parts?.length || 0;
        setCountCart(countst);
    };

    useEffect(() => {
        getCartCount();
    }, []);


    const handleChange = async (e) => {
        setSearchText(e.target.value)

        // mayu code
        const value = e.target.value; 

        if (value.trim()) {
            const { data } = await product_search({
                search: value,
                offset: 0,
                limit: 10,
                category_slug: selectedCategorySlug !== 'All' ? selectedCategorySlug : undefined,
                category_id: selectedCategoryID !== 'All' ? selectedCategoryID : undefined
            });
            setSearchSuggestions(data?.data || []);
        } else {
            setSearchSuggestions([]);
        }

        // eOf mayu code
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        handleClose();
        window.location.href = ("/product?search_text=" + searchText + "&category-slug=" + selectedCategorySlug + "");
        // navigate("/product?search_text=" + searchText + "&category-slug=" + category + "");
    }

    const handleClick = (value, title, id) => {
        // console.log("handlechange---", value)
        setSelectedCategorySlug(value);
        setSelectedCategory(title);
        // mayu code
        setSelectedCategoryID(id);
        // eOf mayu code
    }

    const handleSuggestionClick = (productSlug) => {
        window.location.href = `/product/${productSlug}`;
    };

    // mayu code
    
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setSearchSuggestions([]); // Hide dropdown if clicked outside
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        window.addEventListener("scroll", () => setSearchSuggestions([])); // Hide dropdown on scroll
        return () => {
            document.removeEventListener("click", handleClickOutside);
            window.removeEventListener("scroll", () => setSearchSuggestions([]));
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (!event.target.closest(".header-search")) {
                setSearchSuggestions([]);
            }
        };

        document.addEventListener("click", handleOutsideClick);
        return () => document.removeEventListener("click", handleOutsideClick);
    }, []);
    // eOf mayu code 

   


    return (
        <>
            <div className="">
                <Navbar expand="lg" className="fixed-top navbar  navbar-expand-lg navbar-light pb-0 ps-0 pe-0 pt-0">
                    <Container fluid className="d-flex ps-sm-5 pe-sm-5 ps-2 pe-3">
                        <Navbar.Toggle onClick={handleShow} />
                        {/* <BiMenu size={30} onClick={handleShow}/> */}
                        <Navbar.Brand className="me-auto ">
                            {/* <div className="anchorContainer2 mt-2 mb-2 "> */}
                            <Link className="navbar-brand" to={"/"}>
                                <img className="logo" src={logo} alt={"logo"} onError={(e) => e.target.src = banner} />
                            </Link>
                            {/* </div> */}
                        </Navbar.Brand>

                        <Row className="w-50 text-dark me-auto d-lg-block d-none">
                            <Col className="searchInputDiv">
                                <form onSubmit={handleSubmit} ref={dropdownRef}>
                                    <InputGroup className=" header-search">
                                        <DropdownButton
                                            variant="outline-secondary"
                                            title={selectedCategory}
                                            id="input-group-dropdown-3">
                                            <Dropdown.Item onClick={() => handleClick('All')}>All</Dropdown.Item>
                                            {
                                                categoryList && Array.isArray(categoryList) && categoryList.map((data, index) => {
                                                    return (
                                                        <Dropdown.Item key={index}
                                                            onClick={() => handleClick(data.slug, data.title, data.id)}>{data.title}</Dropdown.Item>
                                                    )
                                                })
                                            }
                                        </DropdownButton>
                                        <Form.Control name={'search_text'} placeholder="&nbsp;Search..."
                                            onChange={handleChange}
                                            aria-label="Text input with 2 dropdown buttons" />
                                        <Button className="search-icon-btn" type="submit"
                                            variant="outline-secondary" id="button-addon2">
                                            <SearchIcon style={{ color: "white" }} />
                                        </Button>
                                        {/* mayu changes: Suggestion dropdown */}
                                            {searchSuggestions.length > 0 && (
                                            <div
                                                className="suggestion-dropdown"
                                                onMouseDown={(e) => e.preventDefault()} // Prevent closing on clicks
                                            >
                                                {searchSuggestions.map((product) => {
                                                    const priceUser = authInfo?.profile
                                                        ? authInfo.profile.user_type === "dealer"
                                                            ? product.amount_total_dealer || 0
                                                            : product.amount_total_customer || 0
                                                        : product.amount_price;

                                                    return (
                                                        <div
                                                            key={product.id}
                                                            className="suggestion-item"
                                                            onClick={() => handleSuggestionClick(product.slug)}
                                                        >
                                                            <img
                                                                src={product.url_icon || banner}
                                                                alt={product.title}
                                                                className="suggestion-image"
                                                                onError={(e) => e.target.src = banner}
                                                            />
                                                            <div className="suggestion-details">
                                                                <p className="suggestion-title">{product.title}</p>

                                                                {product.availability === "Not-Available" || product.availability === "not-available" ? (
                                                                    <p className="suggestion-price">Out of stock</p>
                                                                ) : (
                                                                    product.amount_price === 0 || product.amount_price === 0.0 || product.amount_price === 0.00 ?
                                                                (
                                                                    <p className="suggestion-price">Out of stock</p>
                                                                )
                                                                    :
                                                                (
                                                                    <p className="suggestion-price">₹{priceUser}</p>
                                                                )
                                                                    
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                })}

                                                {/* View All button */}
                                                <div className="view-all-container">
                                                    <Button
                                                        variant="link"
                                                        className="view-all-button"
                                                        onClick={handleSubmit} // Same functionality as submitting the form
                                                    >
                                                        View All
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                        {/* mayu changes end */}
                                    </InputGroup>
                                    
                                </form>

                            </Col>
                        </Row>

                        {isLogin ? <>
                            <Row className="d-sm-block d-none">
                                <Col>
                                    <Notification />
                                </Col>
                            </Row>
                            <Row className="d-sm-none d-block">
                                <Col>
                                    <Avatar onClick={handleShow} alt="notification" className=' d-flex justify-content-center' sx={{ height: "30px", width: "30px", backgroundColor: 'white' }}>
                                        <FaSearch color="var(--primary)" />
                                    </Avatar>
                                </Col>
                            </Row>
                            <Row className="ms-sm-1 me-sm-1 ms-0 me-0">
                                <Col>
                                    <UserDrop />
                                </Col>
                            </Row>
                            <Row className=" text-dark">
                                <Col>
                                    <Link to={"/cart"} style={{ textDecoration: "none", color: "#000000" }}
                                        className={"mx-2"}>
                                        <Badge badgeContent={countCart} color="warning">
                                            <Avatar alt="soping card" sx={{ width: 30, height: 30, bgcolor: "white" }}>
                                                <ShoppingCart size={25} color={"warning"} />
                                            </Avatar>
                                        </Badge>
                                    </Link>
                                </Col>
                            </Row>
                        </> : <div className="d-flex">
                            <div>
                                <div className="d-lg-none d-flex ">
                                    <Avatar onClick={handleShow} alt="notification" className='mt-1 d-flex justify-content-center' sx={{ height: "30px", width: "30px", backgroundColor: 'white' }}>
                                        <FaSearch color="var(--primary)" />
                                    </Avatar>
                                </div>
                                <div className=" d-lg-flex d-none">
                                    <SignUpButton />
                                </div>
                            </div>

                            <div className="">
                                <SignButton />
                            </div>
                        </div>
                        }


                        <Offcanvas show={show} className="offcanvas-sidebar" style={{ width: "70%" }}
                            onHide={handleClose}>
                            <Offcanvas.Header className="pt-0" closeButton>
                                <Offcanvas.Title>
                                    <Link className="navbar-brand" to={"/"}>
                                        <img className="logo" src={logo} alt={"logo"}
                                            onError={(e) => e.target.src = banner} />
                                    </Link>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className="ps-0 pe-0">
                                <Container>
                                    <Row className="mb-2 ">
                                        <Col className="searchInputDiv">
                                            <form onSubmit={handleSubmit}>
                                                <InputGroup className="header-search">
                                                    <DropdownButton
                                                        variant="outline-secondary"
                                                        title={selectedCategorySlug}
                                                        id="input-group-dropdown-3">
                                                        <Dropdown.Item
                                                            onClick={() => handleClick('All')}>All</Dropdown.Item>
                                                        {
                                                            categoryList && Array.isArray(categoryList) && categoryList.map((data, index) => {
                                                                return (
                                                                    <Dropdown.Item key={index}
                                                                        onClick={() => handleClick(data.slug)}>{data.title}</Dropdown.Item>
                                                                )
                                                            })
                                                        }
                                                    </DropdownButton>
                                                    <Form.Control name={'search_text'} placeholder="&nbsp;Search..."
                                                        onChange={handleChange}
                                                        aria-label="Text input with 2 dropdown buttons" />
                                                    <Button className="search-icon-btn" type="submit"
                                                        variant="outline-secondary" id="button-addon2">
                                                        <SearchIcon style={{ color: "white" }} />
                                                    </Button>
                                                </InputGroup>
                                            </form>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Navs handleClose={handleClose} scrollDirection={'down'} />
                                    </Row>
                                </Container>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </Container>
                </Navbar>
                <Navbar style={{ marginTop: '60px', zIndex: 2 }} className=" top-navbar p-0 navbar-nav d-lg-flex d-none ">
                    <Navs handleClose={null} scrollDirection={scrollDirection} />
                </Navbar>
            </div>
            {/* mayu changes: Updated styles */}
            <style jsx>{`
                .dropdown-menu { 
                    z-index: 1001;
                }
                .header-search {
                    position: relative; /* Ensure dropdown aligns with the input group */
                } 
                .suggestion-dropdown {
                    position: absolute;
                    top: 100%; /* Aligns below the input group */
                    left: 0;
                    width: 100%; /* Matches the width of the input group */
                    background: white;
                    border: 1px solid #ddd;
                    z-index: 999;
                    max-height: 300px;
                    overflow-y: auto;
                    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
                } 
                .suggestion-item {
                    display: flex;
                    align-items: center;
                    padding: 10px; /* Adjusted padding for better spacing */
                    height: 60px; /* Fixed height for uniformity */
                    cursor: pointer;
                }
                .suggestion-item:hover {
                    background: #f5f5f5;
                }
                .suggestion-image {
                    width: 50px;
                    height: 50px;
                    object-fit: cover;
                    margin-right: 10px;
                    border-radius: 4px; /* Slight rounding for a polished look */
                }
                .suggestion-details {
                    display: flex;
                    flex-direction: column;
                }
                .suggestion-title {
                    font-size: 14px;
                    margin: 0;
                }
                .suggestion-price {
                    font-size: 12px;
                    color: #888;
                }
                .view-all-container {
                    text-align: center;
                    padding: 10px;
                    border-top: 1px solid #ddd;
                } 
                .view-all-button {
                    font-weight: bold;
                    color: #007bff;
                    cursor: pointer;
                } 
                .view-all-button:hover {
                    text-decoration: underline;
                }
            `}</style>

        </>
    )
}
export default Header