import React, { useEffect, useState } from "react";
import ByCard from "../ByParts/ByCard";
import useWindowSize from "../../Context/WindowSize";

export default function Crouser2({ brands, basePath, id }) {
    const [col, setCol] = useState(4);
    const [total, setTotal] = useState(5);
    const [margin, setMargin] = useState(3);
    const { width } = useWindowSize();

    useEffect(() => {
        if (width < 768) {
            setTotal(2);  // Show only 2 items in mobile view
            setCol(6);     
            setMargin(2);
        } else {
            setTotal(5);
            setCol(2);
            setMargin(3);
        }
    }, [width]);

    useEffect(() => {
        setBrands_details(brands);
    }, [brands]);

    const [brand_details, setBrands_details] = useState(brands);
    const len = Math.ceil(brand_details?.length / total);

    const carouselId = `carousel-${id}`;

    return (
        <div id={carouselId} className="carousel carousel-dark slide" data-bs-ride="carousel">
            {/* Carousel Indicators */}
            <div className="carousel-indicators">
                {Array.from({ length: len }).map((_, idx) => (
                    <button
                        key={`indicator-${id}-${idx}`}
                        type="button"
                        className={idx === 0 ? "active" : ""}
                        data-bs-target={`#${carouselId}`}
                        data-bs-slide-to={idx}
                        aria-current={idx === 0 ? "true" : ""}
                        aria-label={`Slide ${idx + 1}`}
                    />
                ))}
            </div>

            {/* Carousel Items */}
            <div className="carousel-inner ">
                {Array.from({ length: len }).map((_, idxx) => (
                    <div className={`carousel-item ${idxx === 0 ? "active" : ""} ml-5`} key={`slide-${id}-${idxx}`} id="carousal2">
                        <div className="row gy-2 g-3 text-center"> {/* Added `g-3` for spacing */}
                            {brand_details
                                ?.slice(idxx * total, (idxx + 1) * total)
                                .map((element, idx) => (
                                    <div
                                        key={`brand-card-${id}-${idx}`}
                                        className={`col-${col} d-flex justify-content-center align-items-center mx-${margin}` } id="close"
                                    >
                                        <ByCard
                                            image={element?.url_icon}
                                            slug={element?.slug}
                                            title={element?.title}
                                            baseUrl={"book-repair?category-slug="}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                ))}
            </div>

            {/* Prev Button */}
            <button className="carousel-control-prev" type="button" data-bs-target={`#${carouselId}`} data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="visually-hidden">Previous</span>
            </button>

            {/* Next Button */}
            <button className="carousel-control-next " type="button" id="next-btn" data-bs-target={`#${carouselId}`} data-bs-slide="next"  >
                <span className="carousel-control-next-icon" aria-hidden="true" />
                <span className="visually-hidden">Next</span>
            </button>

            {/* Custom Styles */}
            <style>{`

#carousal2{
margin-left:9%;
}

#close{
margin-right: -2rem !important;
}

    /* Default positioning */
    #${carouselId} .carousel-control-prev,
    #${carouselId} .carousel-control-next {
       
        transform: translateY(-6%%);
    }

    #${carouselId} .carousel-control-prev {
        left: -65px !important;
    }

    #${carouselId} .carousel-control-next {
        right: 18px !important;
    }

    /* Adjust button positions for smaller screens */


    

     }
    @media (max-width: 1440px){
     #close{
            margin-right: -1rem !important;
            }

    #${carouselId} .carousel-control-prev {
    left: -13px !important;
    }
    }
    @media(min-width: 768px) and  (max-width: 1200px) {
        #${carouselId} .carousel-control-prev {
            left: -13px !important;
        }
         

        #${carouselId} .carousel-control-next {
            right: -24px !important;
        }
            #close{
            margin-right: 0rem !important;
            }
            #carousal2{
            margin-left:3%;
            }

    }


     }

@media (min-width: 768px) and (max-width: 991px) and (orientation: landscape) {
        #${carouselId} .carousel-control-prev {
            left: -39px !important;
        }
        #next-btn {
            right: -29px !important;
        }
        #close{
         margin-right: -3rem !important;
}
         #carousal2{
            margin-left:2%;
            }
        #carousal2 .carousel-item {
                padding: 10px 15px;  /* Add padding to prevent small boxes */
            }

            #carousal2 .col-6 {
                min-width: 140px;  /* Prevent shrinking */
                max-width: 180px;  /* Ensure it doesn’t get too large */
            }

            #${carouselId} .carousel-control-prev {
                left: -10px !important;
            }

            #${carouselId} .carousel-control-next {
                right: 10px !important;
            }
    }

@media (min-width: 481px) and (max-width: 767px) {
        #${carouselId} .carousel-control-prev,
        #${carouselId} .carousel-control-next {
           
            width: 40px !important; /* Reduce button size for small screens */
        }

        #${carouselId} .carousel-control-prev {
        left: -30px !important;
    }

    #${carouselId} .carousel-control-next {
        right: -36px !important;
    }

    }
   
   @media (min-width: 320px) and (max-width: 480px) {
   #${carouselId} .carousel-control-prev,
        #${carouselId} .carousel-control-next {
           
            width: 40px !important; /* Reduce button size for small screens */
        }

        #${carouselId} .carousel-control-prev {
        left: -30px !important;
    }

    #${carouselId} .carousel-control-next {
        right: -36px !important;
    }
        #close{
            margin-right: -2rem !important;
            }

    }
     #carousal2 {
           margin-left: 3%;
                 }

            }
`}</style>

        </div>
    );
}
