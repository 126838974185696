import { 
    FaCopy, FaExternalLinkAlt, FaMapMarkerAlt, FaTimesCircle, FaBox, FaCheckCircle, FaTruck, FaShippingFast, FaHourglassHalf 
} from "react-icons/fa";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Button
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

export default function TrackingModel({ tracking_url, data }) {
    const [copySuccessId, setCopySuccessId] = useState('');
    const [trackingUrl, setTrackingUrl] = useState(tracking_url);
    const [open, setOpen] = useState(false);
    const trackingData = data?.tracking_response ? JSON.parse(data.tracking_response) : null;
    
    useEffect(() => {
        if (trackingData?.trackHeader?.strShipmentNo) {
            setOpen(true);
        }
    }, [data]);
    
    const handleClose = () => {
        setOpen(false);
        setCopySuccessId("");
    };
    
    function copyToClipboard(text) {
        navigator.clipboard.writeText(text).then(() => setCopySuccessId(text));
    }

    const filteredTrackDetails = trackingData?.trackDetails?.filter((detail, index, self) => 
        index === 0 ||
        detail.strActionDate !== self[index - 1].strActionDate ||
        detail.strActionTime !== self[index - 1].strActionTime ||
        detail.strAction !== self[index - 1].strAction ||
        detail.strOrigin !== self[index - 1].strOrigin
    );

    const getStatusIcon = (status) => {
        switch (status.toLowerCase()) {
            
        
            default: return <FaBox className='status-icon text-secondary' />;
        }
    };
    
    return (
        <>
            <Button variant="contained" style={{background:"#ef5d00"}} onClick={() => setOpen(true)}>
                <FaMapMarkerAlt style={{ marginRight: "8px" }} /> Track Order
            </Button>
            
            <Dialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
            <DialogTitle style={{ borderBottom: '1px solid grey', paddingBottom: '16px' }}>
            Track Your Shipment
                <IconButton aria-label="close" onClick={handleClose} style={{ position: 'absolute', right: 8, top: 8, color: "red" }}>
                    <FaTimesCircle />
                </IconButton>
            </DialogTitle>
                <DialogContent>
                    {trackingData ? (
                        <>
                            <Row className='mb-3'>
                                <Col><small>Tracking ID</small></Col>
                                <Col>
                                    <h6 className='text-dark'>
                                        {trackingData.trackHeader.strShipmentNo} 
                                      
                                    </h6>
                                </Col>
                            </Row>
                           
                            <h5 className='mt-4'>Current Status</h5>
                            <div className="w-100 box">
                            
                                <div className="col-md-12 d-flex justify-content-center trackingInfo">
                                    <div className="tracking-timeline">
                                        {filteredTrackDetails?.map((detail, index) => (
                                            <div className={`timeline-item ${index === 0 ? 'current-status' : ''}`} key={index}>
                                                <div className="timeline-dot">{getStatusIcon(detail.strAction)}</div>
                                                <div className="timeline-date me-2">
                                                    {detail.strActionDate} <br />
                                                    {detail.strActionTime.substring(0,2)}:{detail.strActionTime.substring(2,4)}
                                                </div>
                                                <div className="timeline-content">
                                                    <h5>{detail.strAction}</h5>
                                                    <p>{detail.strOrigin}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                           </div>
                        </>
                    ) : (
                        <p>No tracking details available.</p>
                    )}
                </DialogContent>
            </Dialog>
            <style>{`
            .box{
                height:356px;
            }
            .trackingInfo { 
                padding: 5px 20px;
                position: relative;
                width: 100%;
            
                overflow: hidden;
                overflow-y: auto;   
            }
            .tracking-timeline {
                border-left: 4px solid orange; /* Timeline line color */
                padding-left: 40px;
                position: relative;
                min-height: 100%; /* Ensure the timeline stretches to the full height */
            }
            .timeline-item {
                margin-bottom: 20px;
                position: relative;
                display: flex;
                align-items: center;
            }
            .timeline-dot {
                position: absolute;
                left: -85px;
                font-size: 22px;
                color: orange; /* Default dot color */
            }
            .timeline-item::before {
                content: "";
                position: absolute;
                left: -50px;
                top: 0;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: orange; /* Default dot background color */
                border: 2px solid #fff;
            }
            .timeline-item.current-status::before {
                background: orange; /* Current status dot color */
            }
            .timeline-item.current-status .timeline-dot {
                color: orange; /* Current status dot icon color */
            }
            .status-icon {
                font-size: 24px;
                margin-right: 10px;
            }
            .timeline-date {
                font-size: 15px;
                color: #666;
                margin: 5px;
            }
            .timeline-content h5 {
                margin: 0;
                font-size: 16px;
                font-weight: bold;
                color: orange; /* Default text color */
            }
            .timeline-item.current-status .timeline-content h5 {
                color: orange; /* Current status text color */
            }
            .timeline-content p {
                margin: 0;
                font-size: 14px;
                color: #333;
            }
            `}</style>
        </>
    );
}
