import {InputField} from "../InputFields/InputFieldHelper";
import {CCol} from "@coreui/react";
import {Form} from "react-bootstrap";
import {Autocomplete, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {address_state, category_list, get_data_by_url} from "../../api/general";
import {useNotification} from "../../hooks";

export default function BecomeAPartnerForm({ refContent,userType, userTypeObject}) {

    const {showError, showSuccess} = useNotification();
    const [addressStates, setAddressStates] = useState([]);
    const [addressCities, setAddressCities] = useState([]);
    const [buttonName, setButtonName] = useState("Submit");
    const [category_details, setCategory_details] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [inputData, setInputData] = useState({
        name: "",
        mobile: "",
        name_state: "",
        name_city: "",
        user_type: "",
        // categories: ""
        // category: ""
    });

    useEffect(() => {
        setInputData({...inputData, type: userType});
    }, [userType])


    const getCityName = async (stateName) => {
        const data = await address_state({
            type: "name_city",
            name_state: stateName || "",
            offset: 0,
            limit: 10000
        }).then((response) => response.data);
        if (data?.error) {
            setAddressCities([]);
        } else {
            setAddressCities(data.data);
        }
    };

    const getStateNames = async () => {
        const {data} = await address_state({
            type: "name_state",
            offset: 0,
            limit: 1000
        });
        setAddressStates(data.data);
    };

    const getCategories = async (inputData) => {
        const {data} = await category_list(inputData);
        if (data.data && Array.isArray(data.data)) setCategory_details(data.data);
        return data;
    };

    const handleChange = ({target}) => {
        const {value, name} = target;
        if (name === 'name_state') {
            getCityName(value);
        }
        setInputData({...inputData, [name]: value});
    };
    const onCategoryChange = async (event, category) => {
        // console.log(category);
        setSelectedCategory(category);
    };

    useEffect(() => {
        getStateNames();
        getCategories();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setButtonName("loading...");
        const form = event.currentTarget
        if (form.checkValidity() === false) {
            setButtonName("Submit");
            event.stopPropagation();
        } else {
            let token_categories = [];
            let title_category = "";
            if (selectedCategory && Array.isArray(selectedCategory)) {
                selectedCategory.map((cat, indx) => {
                    if (cat.token) token_categories = [...token_categories, cat?.token];

                    if (title_category === "") {
                        title_category = cat.title;
                    } else {
                        title_category += ", " + cat.title;
                    }
                })
                inputData.title_category = title_category;
                inputData.token_categories = token_categories;
            }

            // console.log(inputData);

            let response = await get_data_by_url("dealer/general/become-partner-update", inputData);
            // console.log("Res")
            // console.log(data.token);
            if (response.error) {
                // console.log("err insert")
                showError(response?.message);
                setButtonName("Submit");
            } else {
                showSuccess(response?.message);
                window.location.reload();
                setInputData({
                    name: "",
                    mobile: "",
                    email: "",
                    name_state: "",
                    name_city: "",
                    type: "",
                    categories: ""
                })
            }
        }
    };

    return (
        <>
           <div className="mx-md-5 mx-2">
               <form  onSubmit={handleSubmit}>
                   <div className="row">
                       <div  className="col-md-12 col-lg-12  my-3 px-2">

                           <div className={''}>
                               {/*{userTypeObject?.description}*/}
                               {/*<div className="card-body">*/}
                               <h3>Become a <span className={'color-primary'}>{userTypeObject?.title}</span></h3>
                               <p>Are you looking for a cool business chance that lets you work flexibly and grow a lot?
                                   Well,
                                   guess what? Our company, iService Innovation Service, wants awesome people like you to
                                   join
                                   us.</p>
                               <p>If you become a partner, you get to be part of a fun team and help the business grow.
                                   We'll
                                   give you good training and support to do well in your job. Plus, we already have a plan
                                   that
                                   makes a lot of money and makes customers happy.
                                   Being our partner means you get cool stuff like flexible hours, a chance to make a bunch
                                   of
                                   money, and the option to build your own team. You also get special tools to help you
                                   work
                                   better.</p>
                               <p>So, join us now for a fun adventure where there's no limit to how much you can succeed.
                                   Don't miss out on being a important part of our awesome business. Contact us today to
                                   learn
                                   more about this great chance! Become a partner now!</p>
                               {/*</div>*/}

                           </div>
                       </div>
                       <div ref={refContent} className="col-md-12 col-lg-12  my-3 px-4">
                           <div className="row g-2">
                               <div className="col-lg-12">
                                   <h5 >Please provide following details.</h5>
                               </div>
                               <div className="col-md-6 ">
                                   <InputField label="Name"
                                               name={'name'}
                                               value={inputData.name}
                                               onChange={handleChange}
                                               className={'form-control'}
                                               required={true}
                                               color={"warning"}
                                               placeholder="Please enter your name"/>
                               </div>
                               <div className="col-md-6">
                                   <InputField label="Mobile Number" size={'small'}
                                               variant="outlined"
                                               name={'mobile'}
                                               value={inputData.mobile}
                                               min={1000000000} max={9999999999}
                                               onChange={handleChange}
                                               required={true}
                                               color={"warning"}
                                               className={'form-control'}
                                               placeholder="Please enter your mobile number"/>

                               </div>
                               <div className="col-md-6">
                                   <InputField type={'text'} minLength={3} name={'name_state'}
                                               label={'State'} value={inputData?.name_state}
                                               placeholder={'Please enter state name'}
                                               onChange={handleChange} required={true}
                                               options={addressStates}/>
                               </div>
                               <div className="col-md-6">
                                   <InputField type={'text'} minLength={3} name={'name_city'}
                                               label={'City'} value={inputData?.name_city}
                                               placeholder={'Please enter city name'}
                                               onChange={handleChange} required={true}
                                               multiple={true}
                                               options={addressCities}/>
                               </div>
                               <CCol md={12}>
                                   <Form.Label className={"mb-2"} style={{fontWeight: "lighter"}}><strong>Select
                                       Category</strong></Form.Label>
                                   <Autocomplete
                                       color={"warning"}
                                       size={'small'}
                                       multiple
                                       onChange={onCategoryChange}
                                       id="tags-outlined"
                                       options={category_details}
                                       getOptionLabel={(option) => option.title}
                                       // defaultValue={}
                                       filterSelectedOptions
                                       renderInput={(params, index) => (
                                           <TextField key={"cat-" + index}
                                                      color={"warning"}
                                                      {...params}
                                                      label=""
                                                      placeholder="Select Category"
                                           />
                                       )}
                                   />
                               </CCol>
                           </div>


                           <div className="d-flex justify-content-center">
                               <button type="submit" className="btn btn-primary3 my-3 ">
                                   {buttonName}
                               </button>
                           </div>
                       </div>
                   </div>
               </form>
           </div>
        </>
    );
}