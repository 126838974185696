import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {UseScroll} from "./UseScroll";
import {useAuth} from "../hooks";

const Navs = ({handleClose, scrollDirection}) => {
    const [isActiveMenu, setActiveMenu] = useState('home');
    const {userLogout, authInfo} = useAuth();
    const pathArray = window.location.pathname.split("/");

    useEffect(() => {
        if (pathArray[1]) {
            setActiveMenu(pathArray[1]);
        } else {
            setActiveMenu('home');
        }
        // console.log(pathArray)
    }, [pathArray, authInfo]);
    const navigationMenus = [
        {
            'title': "Home",
            'link': "/",
            'is_active': (isActiveMenu === 'home' ? 'active' : ''),
            'for': 'defult'
        }, {
            'title': "Services",
            'link': "/services",
            'is_active': (isActiveMenu === 'services' ? 'active' : ''),
            'for': 'defult'
        },
        {
            'title': "Orders",
            'link': "/booking",
            'is_active': (isActiveMenu === 'booking' ? 'active' : ''),
            'for': 'both'
        }
        , 
        // {
        //     'title': "JobSheet",
        //     'link': "/job-sheet",
        //     'is_active': (isActiveMenu === 'job-sheet' ? 'active' : ''),
        //     'for': 'dealer'
        // },
         {
            'title': "Parts",
            // 'link': "/shop",
            'link': "/product",
            'is_active': (isActiveMenu === 'product' || isActiveMenu === 'shop' ? 'active' : ''),
            'for': 'defult'
        }, {
            'title': "About Us",
            'link': "/about",
            'is_active': (isActiveMenu === 'about' ? 'active' : ''),
            'for': 'defult'
        }, {
            'title': "Contact Us",
            'link': "/contact-us",
            'is_active': (isActiveMenu === 'contact-us' ? 'active' : ''),
            'for': 'defult'
        },
    ];
    return (
        <>
            <div className={'navbar-light navbar-nav navbar-collapse collapse show nav-temp pb-0 '}
                 id="navbarSupportedContent">
                <ul className={scrollDirection === "down" ? "navbar-nav nav-active" : "navbar-nav nav-hidden"}>
                    {
                        navigationMenus.map((menu, index) => {

                            let isShow = false;
                            if (menu?.for === 'both') {
                                if (authInfo?.profile?.user_type) {
                                    isShow = true;
                                }
                            } else if (menu?.for === 'dealer') {
                                if (authInfo?.profile?.user_type === 'dealer') {
                                    isShow = true;
                                }
                            } else {
                                isShow = true;
                            }

                            if (isShow) {
                                return (<li className="nav-item px-3 " key={menu?.link || index}>
                                    <Link onClick={handleClose} to={menu?.link} style={{textDecoration: "none"}}
                                          className={"nav-link " + (menu.is_active || "")} aria-current="page">
                                        {menu?.title}
                                    </Link>
                                </li>);
                            } else {
                                return "";
                            }
                        })
                    }
                </ul>

            </div>

        </>
    )
}
export default Navs;